import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export function userGraphReducer(state = initialState, action) {
    switch (action.type) {
        case types.SAVE_USER_CHART:
            {
                let tempData = { 'date': new Date().toDateString(), 'chart_name': action.userdata.chartname, 'user_name': action.userdata.username }
                return { ...state, [action.userdata.chartname]: tempData };
            }
        default:
            return state;
    }
}

export function usersAllGraphReducer(state = initialState.userCharts, action) {
    switch (action.type) {       
        case types.GET_USER_CHARTS_SUCCESS:
            return action.status ?? [] ;
        default:
            return state;
    }
}
