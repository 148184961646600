import React, { useEffect } from "react";
import User from "../../assets/user.svg";
import { useHistory } from "react-router-dom";
import QuicksightMenuList from "../common/QuicksightMenuList";
import { Auth } from "aws-amplify";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import "./header.css";
const Header = (props) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://www.principalcdn.com/css/principal-design-system/enterpriseHeader/v4/enterpriseHeader.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #d3d4d5",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      "&:focus": {
        backgroundColor: "#C5C5C5",
        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Logout = () => {
    localStorage.removeItem("startpage");
    localStorage.clear();
    sessionStorage.clear();
    Auth.signOut();
    // history.push("/login");
    history.push({
      pathname: '/',
      search: '?code=logout',
    });
  };

  return (
    <div>
      <nav className="navbar navbar-default">
        <div className="navbar-header">
          <a className="navbar-principal-brand" href="/"></a>
        </div>
        <div className="navbar-text active">
          <QuicksightMenuList dropdownType="Prod" />
        </div>
        <div className="navbar-text active">
          <QuicksightMenuList dropdownType="Dev" />
        </div>
        <div>
          <a className="navbar-text on-premise-menu" href="/onPremiseReport">
            On Premises Reports
          </a>
        </div>
        <div>
          <a className="navbar-text on-premise-menu" href="/applications">
            Applications
          </a>
        </div>
        <div>
          <a className="navbar-text on-premise-menu" href="/selfService">
            Self-Service
          </a>
        </div>
        <div>
          <a className="navbar-text on-premise-menu" href="/feedback">
            Feedback
          </a>
        </div>
        <div>
          <a className="navbar-text on-premise-menu" style={{fontWeight: "600"}}
             href={window.location.origin + "/v2/"}>
            Switch to Launchpad v2
          </a>
        </div>
        <div className="navbar-text">
          <select
              value="-1"
              className="dropdown bg-primary"
              style={{ width: "70px", fontweight: "bold", border: "none" }}
              onChange={(e)=>history.push(e.target.value)}
          >
            <option key="-1" value="/">
              More
            </option>
            <option value="/apiCategory">
              Upload/Download
            </option>
            <option value="/quickSightSetting">
              QS manage users
            </option>
            {/*<option value="/referenceData">*/}
            {/*  Reference Data*/}
            {/*</option>*/}
          </select>
        </div>
        {
          <div
            style={{ marginRight: "5px" }}
            className="navbar-text navbar-text-font-size-18 navbar-right"
          >
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <img height="30" src={User} alt="User Logo" />
            </Button>
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              autoFocusItem={Boolean(anchorEl)}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem>
                <span className="menu-font-size">
                  {localStorage.getItem("userName")}
                </span>
              </MenuItem>
              <div className="menuitem-border-bottom"></div>
              <MenuItem onClick={Logout}>
                <span className="menu-font-size">
                  Logout
                </span>
                {/* <img src={LogoutIcon} /> */}
              </MenuItem>
            </StyledMenu>
          </div>
        }
      </nav>
    </div>
  );
};

export default React.memo(Header);
