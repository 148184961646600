import axios from "axios";

export async function handleResponse(response) {
  if (response.status === 200) return response.data;
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    //console.log("error ", response)
    await response.text();
    //throw new Error(error);
  }
  //throw new Error("Network response was not ok.");
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
  // eslint-disable-next-line no-console
  console.error("API call failed. ");
  //throw error;
}

export async function alphaForecastPOST(fileName, file) {
  return axios
    .post(
      process.env.REACT_APP_ALPHAFORECAST_TAASEGMENT_POST + fileName,
      file,
      {
        headers: {
          "Content-Type": "text/csv",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
}

export async function uploadPAATAASAAStrategy(fileName, file) {
  return axios
    .get(
      process.env.REACT_APP_ALPHAFORECAST_TAASTRATEGY_GET+"?filename="+fileName,
      {
        headers: {
          "Content-Type": "text/csv",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    )
    .then((res) => {
      return preSignedS3Upload(res.data.file_url, file, fileName);
      // return res;
    })
    .catch((error) => {
      return error;
    });
}

export async function preSignedS3Upload(presignedPostData, file, fileName) {
    const formData = new FormData();
    Object.keys(presignedPostData.fields).forEach(key => {
        formData.append(key, presignedPostData.fields[key]);
    });
    formData.append("file", file);
    return axios
        .post(
            presignedPostData.url,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }
        )
        .then((res) => {
            console.log(res);
            return res;
        })
        .catch((error) => {
            return error;
        });
}

export async function alphaForecastGET(valDate) {
  return axios
    .get(process.env.REACT_APP_ALPHAFORECAST_TAASEGMENT_GET + valDate, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
}

export async function uploadReferenceData(type, filename, file) {
  return axios
    .post(
      process.env.REACT_APP_ALPHAFORECAST_DEALALLOCATION_POST +
        "filename=" +
        filename +
        "&filetype=" +
        type,
      file,
      {
        headers: {
          "Content-Type": "text/csv",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function getAllReports(menuName) {
  return axios
    .get(process.env.REACT_APP_ONPREM_LIST + menuName, {
      headers: {
        "Content-Type": "text/csv",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return e;
    });
}

export async function updateDeleteOnPrem(requestData, action) {
  return axios
    .post(
      process.env.REACT_APP_ONPREM_UPDATE + "?action=" + action,
      requestData,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function getReportCategory(data) {
  return axios
    .post(process.env.REACT_APP_REPORT_CATEGORY, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
}

export async function addReport(data, action) {
  return axios
    .post(process.env.REACT_APP_ONPREM_UPDATE + "?action=" + action, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export async function addDeleteReport(data) {
  return axios
    .post(process.env.REACT_APP_REPORT_CATEGORY, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export async function getAllQSGroups() {
  return axios
    .post(process.env.REACT_APP_QSLIST_GROUP, "", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export async function deleteQSGroup(group_name, account) {
  return axios
    .post(
      process.env.REACT_APP_QSDELETE_GROUP +
        "group_name=" +
        group_name +
        "&&account_id=" +
        account,
      "",
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export async function createQSGroup(group_name, group_description, account) {
  let description = {
    description: group_description,
  };
  return axios
    .post(
      process.env.REACT_APP_QSCREATE_GROUP +
        "group_name=" +
        group_name +
        "&&account_id=" +
        account,
      description,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export async function listUserByGroup(group_name, account) {
  return axios
    .post(
      process.env.REACT_APP_GROUP_USER_LIST +
        "group_name=" +
        group_name +
        "&&account_id=" +
        account,
      "",
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export async function deleteUserAPI(group_name, account, member_name) {
  return axios
    .post(
      process.env.REACT_APP_QS_USER_DELETE +
        "group_name=" +
        group_name +
        "&&account_id=" +
        account,
      { user_names: member_name },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export async function searchUser(account, group_name, NextToken) {
  return axios
    .get(
      process.env.REACT_APP_QSUSER_LIST +
        "account_id=" +
        account +
        "&group_name=" +
        group_name + (NextToken ? ("&NextToken="+NextToken) : ""),
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export async function addUserAPI(group_name, account, member_name) {
  return axios
    .post(
      process.env.REACT_APP_QS_ADD_USER +
        "group_name=" +
        group_name +
        "&&account_id=" +
        account,
      { user_names: member_name },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
