import React, { Component } from "react";
import axios from "axios";
import * as QuickSightEmbedding from "amazon-quicksight-embedding-sdk";
import { Unauthorized } from "../errorPages/Unauthorized.tsx";

/**Show Quicksight Dashboard
 * Parameters :- Get Quicksight ID
 * Process :- Using quicksight ID generate embedded URL and pass embedded URL to QuickSightEmbedding
 */

class QuickSightDashBoards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      isLoading: false,
      dashboard_id: "",
      isEmbeddedError: false,
      divVisiblity: "visible",
    };
    this.min25 = ((4 * 60) +59)* 1000;
  }

  embedDashboard(embedUrl) {
    this.setState({ divVisiblity: "visible" });
    var containerDiv = document.getElementById("dashboardContainer");
    containerDiv.innerHTML = "";
    var params = {
      url: embedUrl,
      container: containerDiv,
      width: "100%",
      height: "100%",
      printEnabled: false,
      undoRedoDisabled: true,
      resetDisabled: false,
    };
    QuickSightEmbedding.embedDashboard(params);
    this.setState({ url: "", isLoading: false });
  }

  loadDashboard() {
    const search = this.props.location.search;
    const dashboardID = new URLSearchParams(search).get("dashboard_id");

    var userEmail = localStorage.getItem("userEmail");

    if (dashboardID !== this.state.dashboard_id) {
      this.setState({ dashboard_id: dashboardID });
      this.setState({ url: "", isLoading: true });
      this.generateEmbeddedURL(dashboardID, userEmail);
    }
  }

  generateEmbeddedURL(dashboardID, userEmail) {
    const api =
      process.env.REACT_APP_QUICKSIGHT_GENERATE_EMBEDDED_URL +
      dashboardID +
      "&email=" +
      userEmail;
    this.setState({ isEmbeddedError: false });
    axios
      .get(api, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        this.embedDashboard(res.data);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          this.setState({ isEmbeddedError: true });
          this.setState({ divVisiblity: "hidden" });
        } else {
          this.setState({ isEmbeddedError: false });
        }
      });
  }
  componentDidMount() {
    const search = this.props.location.search;
    let dashboardTitle = new URLSearchParams(search).get("dashboard_title");
    if(!dashboardTitle){
      dashboardTitle = localStorage.getItem("selected_dashboard");
    }
    if(dashboardTitle){
      document.title = dashboardTitle;
    }

    this.loadDashboard();
  }
  componentDidUpdate(prevProps) {
    this.loadDashboard();
  }
  render() {
    return (
      <>
        {this.state.isEmbeddedError && <Unauthorized />}
        <div
          id="dashboardContainer"
          style={{ height: "1000px", visibility: this.state.divVisiblity }}
        >
          {this.state.isEmbeddedError}
        </div>
      </>
    );
  }
}

export default QuickSightDashBoards;
