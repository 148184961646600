import { combineReducers } from "redux";
import { tickerReducer as tickers, tickerDataReducer as tickersData}  from "./tickerReducrer";
import apiCallsInProgress from "./apiStatusReducer";
import windowZIndex from "./commanStateReducer";
import { usersAllGraphReducer as userCharts} from "./userReducers";
import { userSession, allUsers } from "./userSessionReducre";
import { userGroupChartReducer as groupChartDetails } from "./groupChartReducers";
import {LoginReducer} from "./login.reducer"

const rootReducer = combineReducers({
  windowZIndex,
  tickers,
  tickersData,
  userCharts,
  userSession,
  allUsers,
  groupChartDetails,
  apiCallsInProgress,
  LoginReducer
});

export default rootReducer;
