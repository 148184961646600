import React from "react";

export default function Button(props) {
	return (
		<button onClick={props.onClick} disabled={props.disabled ? false : true} className={`btn btn-primary ${props.size ? "btn-"+props.size : ""}`} data-testid="test_button">{props.value}</button>
	)
}

export function SolidButton(props) {
	return (
		<button onClick={props.onClick} disabled={props.disabled ? false : true} className={`btn btn-primary ${props.size ? "btn-"+props.size : ""}`} data-testid="test_button">{props.value}</button>
	)
}

export function ButtonDefault(props) {
	return (
		<button onClick={props.onClick} disabled={props.disabled ? false : true} className={`btn btn-default ${props.size ? "btn-"+props.size : ""}`} data-testid="test_button">{props.value}</button>
	)
}