import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function commanStateReducer(state = initialState.windowZIndex, action){
    switch(action.type){
        case types.UPDATE_WINDOW_Z_INDEX:
            return state += 1;
        default:
            return state;
    }
}