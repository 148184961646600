export const CREATE_COURSE = "CREATE_COURSE";
export const LOAD_COURSES_SUCCESS = "LOAD_COURSES_SUCCESS";
export const LOAD_AUTHORS_SUCCESS = "LOAD_AUTHORS_SUCCESS";
export const CREATE_COURSE_SUCCESS = "CREATE_COURSE_SUCCESS";
export const UPDATE_COURSE_SUCCESS = "UPDATE_COURSE_SUCCESS";

//SYMBOL AND DATA
export const LOAD_TICKERS_SUCCESS = "LOAD_TICKERS_SUCCESS";
export const GET_TICKER_DATA_SUCCESS = "GET_TICKER_DATA_SUCCESS";

export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";

//CHART WINDOW ACTION
export const UPDATE_WINDOW_Z_INDEX = "UPDATE_WINDOW_Z_INDEX";

//USER ACTIONS
export const SAVE_USER_CHART = "SAVE_USER_CHART";
export const GET_USER_CHARTS_SUCCESS = "GET_USER_CHARTS_SUCCESS";

//AITHENTICATION
export const SAVE_MS_GRAPH_TOKEN = "SAVE_MS_GRAPH_TOKEN";
export const GET_ALL_USERS_MS_GRAPH_API = "GET_ALL_USERS_MS_GRAPH_API";
export const SET_USER_SESSION = "SET_USER_SESSION";

//GROUP CHART DETAILS
export const SAVE_GROUP = "SAVE_GROUP";
export const REMOVE_GROUP = "REMOVE_GROUP";
export const SAVE_CHART_TO_GROUP = "SAVE_CHART_TO_GROUP";
export const REMOVE_CHART_FROM_GROUP = "REMOVE_CHART_FROM_GROUP";
export const SAVE_MEMBER_TO_GROUP = "SAVE_MEMBER_TO_GROUP";
export const REMOVE_MEMBER_FROM_GROUP = "REMOVE_MEMBER_FROM_GROUP";
export const GET_CHART_GROUP_DETAILS = "GET_CHART_GROUP_DETAILS";
export const CHNAGE_MEMBER_PERMISSION = "CHNAGE_MEMBER_PERMISSION";

// By convention, actions that end in "_SUCCESS" are assumed to have been the result of a completed
// API call. But since we're doing an optimistic delete, we're hiding loading state.
// So this action name deliberately omits the "_SUCCESS" suffix.
// If it had one, our apiCallsInProgress counter would be decremented below zero
// because we're not incrementing the number of apiCallInProgress when the delete request begins.
export const DELETE_COURSE_OPTIMISTIC = "DELETE_COURSE_OPTIMISTIC";
