import React, { FC } from "react";

export const Unauthorized: FC = () => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        marginTop: "-80px",
        marginBottom: "0px",
        paddingBottom: "0px",
        maxHeight: "0px",
        minHeight: "0px",
      }}
    >
      <div className="pds-unauthenticatedError pds-unauthenticatedError-background">
        <section className="pds-imgStanTheMan">
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div className="pds-typography-center">
            <div>
              <h1 className="pds-typography-h2" style={{ textAlign: "center" }}>
                Pardon me?
              </h1>
            </div>
            <div className="pds-util-padding-horz-10">
              <p
                className="pds-util-margin-vert-0"
                style={{ textAlign: "center" }}
              >
                It looks like you don't have permission to view this page. If
                you think you should have permission, contact your primary
                administrator.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
