import React, { FC, useEffect, useState } from "react";
import {
  alphaForecastGET,
  alphaForecastPOST,
  uploadPAATAASAAStrategy,
  uploadReferenceData,
} from "../../api/apiUtils";
import { saveAs } from "file-saver";
import paa_taa_saa_MMDDYY from "../../assets/templates/paa_taa_saa_MMDDYY.csv"
import {Unauthorized} from "../errorPages/Unauthorized.tsx";

export const AlphaForcastAPI: FC = () => {
  const { uploadList } = {
    uploadList: [
      { apiCategory: "Deal Allocation", apiName: "WAL Segment Assumptions" },
      { apiCategory: "Deal Allocation", apiName: "Target Volume Assumptions" },
      {
        apiCategory: "Deal Allocation",
        apiName: "Asset Subclass level Assumptions",
      },
      {
        apiCategory: "Deal Allocation",
        apiName: "Purchases to Level 4",
      },{
        apiCategory: "PAA-TAA-SAA strategy",
        apiName: "PAA-SAA by Segment Load to Reservoir file",
      },
    ],
  };
  const downloadList = [
    { apiCategory: "TAA by Segment", apiName: "PAA-SAA and Spreads by Segment Load to ALFA file" },
  ];
  const groupAndCategoryPermission = {
    "PAA-TAA-SAA strategy": ["GA_LAUNCHPAD_UPDATE_GROUP", "GA_LAUNCHPAD_ALFA_GROUP"],
    "TAA by Segment": ["GA_LAUNCHPAD_UPDATE_GROUP", "GA_LAUNCHPAD_ALFA_GROUP"],
    "Deal Allocation": ["GA_LAUNCHPAD_UPDATE_GROUP"]
  }
  const apiCategory = sessionStorage.getItem("apiCategory") || "";
  //Write find logic here
  const uploadItems = uploadList.filter((e) =>
    Object.values(e).some(
      (val) => typeof val === "string" && val.includes(apiCategory)
    )
  );
  const downloadItems = downloadList.filter((e) =>
    Object.values(e).some(
      (val) => typeof val === "string" && val.includes(apiCategory)
    )
  );
  const [dealAllocationFlag, setDealAllocationFlag] = useState<null | string>(
    null
  );
  const [fileErrFlag, setFileErrFlag] = useState(false);
  const [dateErrFlag, setDateErrFlag] = useState(false);
  const [fileNotFound, setFileNotFound] = useState(false);
  const [fileSelect, setFileSelect] = useState<null | boolean>(null);
  const [fileUploadSuccess, setFileUploadSuccess] = useState<null | boolean>(
    null
  );
  const [fileDownloadSuccess, setFileDownloadSuccess] = useState<
    null | boolean
  >(null);
  const [fileUploadMessage, setFileUploadMessage] = useState();
  const [fileDownloadMessage, setFileDownloadMessage] = useState();
  const [selectedAPIType, setSelectedAPIType] = useState("");
  const [selectedAPIName, setSelectedAPIName] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [downloadAllInput, setDownloadAllInput] = useState(false);
  const [uploadAllInput, setUploadAllInput] = useState(false);
  const fileFormat = {
    "paa-taa-saa-strategy-file": "paa_taa_saa_MMDDYY.csv (Example: paa_taa_saa_033123.csv)"
  };
  let isUpdateAllowed = false;

  let userGroup = localStorage.getItem("group") || "";
  if(groupAndCategoryPermission[apiCategory].length > 0){
    groupAndCategoryPermission[apiCategory].forEach((group:string) => {
      if(userGroup.indexOf(group)>=0){
        isUpdateAllowed = true;
      }
    });
  }

  const [alertData, setAlertData] = useState({
    show: false,
    message: "",
    type: "success"
  });

  const showAlert = (type, message, timeout?) =>{
    setAlertData({
      show: true,
      message: message ?? "Done",
      type: type ?? "success"
    })

    let alertTimeout = timeout ?? 5000;

    if(alertTimeout !== -1){
      setTimeout(()=>{
        setAlertData({
          show: false,
          message: "",
          type: "success"
        })
      }, 5000)
    }

  }

  useEffect(() => {
    document.title = "Upload/Download";
    const script = document.createElement("script");
    script.src = "https://www.principalcdn.com/css/horizon/v2/horizon.min.js";
    script.async = true;
    document.body.appendChild(script);
    const coreScript = document.createElement("script");
    coreScript.src =
      "https://www.principalcdn.com/css/principal-design-system/core/v20/core.min.js";
    coreScript.async = true;
    document.body.appendChild(coreScript);
    const radioScript = document.createElement("script");
    radioScript.src =
      "https://www.principalcdn.com/css/principal-design-system/selectionCard/v31/selectionCard.min.js";
    radioScript.async = true;
    document.body.appendChild(radioScript);
    const uploadScript = document.createElement("script");
    uploadScript.src =
      "https://www.principalcdn.com/css/principal-design-system/fileUpload/v11/fileUpload.min.js";
    uploadScript.async = true;
    document.body.appendChild(uploadScript);
  }, []);

  const handleAPISelectionChange = (apiType, apiName) => {
    let dealFlagFunc;
    setSelectedAPIType(apiType);
    setSelectedAPIName(apiName);
    if (apiName === "Target Volume Assumptions") {
      setDealAllocationFlag("target_volume_assumption");
      dealFlagFunc = "target_volume_assumption";
      if (selectedFileName !== "") {
        setFileErrFlag(
          !selectedFileName.startsWith("target_volume_assumptions")
        );
      }
    } else if (apiName === "WAL Segment Assumptions") {
      setDealAllocationFlag("wal_segments_assumptions");
      dealFlagFunc = "wal_segments_assumptions";
      if (selectedFileName !== "") {
        setFileErrFlag(!selectedFileName.startsWith("wal_segment_assumption"));
      }
    } else if (apiName === "Asset Subclass level Assumptions") {
      setDealAllocationFlag("subclass_level_assumptions");
      dealFlagFunc = "subclass_level_assumptions";
      if (selectedFileName !== "") {
        setFileErrFlag(
          !selectedFileName.startsWith("subclass_level_assumption")
        );
      }
    } else if (apiName === "Purchases to Level 4") {
      setDealAllocationFlag("purchases_to_level4");
      dealFlagFunc = "purchases_to_level4";
      if (selectedFileName !== "") {
        setFileErrFlag(!selectedFileName.startsWith("purchases_to_level4"));
      }
    } else if (apiName === "PAA-SAA by Segment Load to Reservoir file"){
      setDealAllocationFlag("paa-taa-saa-strategy-file");
      dealFlagFunc = "paa-taa-saa-strategy-file";
      if (selectedFileName !== "") {
        setFileErrFlag(!selectedFileName.startsWith("purchases_to_level4"));
      }
    }
    else {
      setDealAllocationFlag(null);
      dealFlagFunc = null;
      if (selectedFileName !== "") {
        setFileErrFlag(!selectedFileName.startsWith("PurchaseAssets"));
      }
    }
    checkRegexOnRadioChange(dealFlagFunc, selectedFileName);
  };

  const fileUpload = async () => {
    console.log("fileUpload");
    setFileSelect(selectedFileName === "");
    if (
      selectedAPIType !== "upload" ||
      selectedAPIName === "" ||
      selectedFileName === ""
    ) {
      //Set Error all upload
      setUploadAllInput(true);
    } else {
      setUploadAllInput(false);
    }
    if (!fileErrFlag && selectedFileName !== "") {
      showAlert("information", "Uploading file ...", -1);
      if (dealAllocationFlag === null) {
        let res = await alphaForecastPOST(selectedFileName, selectedFile);
        if (res.status === 200) {
          document.querySelector("#pds-file-upload-input-box").files =
            new DataTransfer().files;
          // setFileUploadSuccess(true);
          showAlert("success", "File uploaded successfully!");
          setSelectedFileName("");
          setSelectedAPIName("");
        } else {
          // setFileUploadSuccess(false);
          showAlert("error", res?.data?.error ?? "There was an error uploading file. Please try again.");
        }
      }
      else if (
        dealAllocationFlag === "target_volume_assumption" ||
        dealAllocationFlag === "wal_segments_assumptions" ||
        dealAllocationFlag === "subclass_level_assumptions" ||
        dealAllocationFlag === "purchases_to_level4"
      ) {
        let res = await uploadReferenceData(
          dealAllocationFlag,
          selectedFileName,
          selectedFile
        );
        if (res.status === 200) {
          // setFileUploadSuccess(true);
          showAlert("success", "File uploaded successfully!");
        } else {
          // setFileUploadMessage(res.data.error);
          // setFileUploadSuccess(false);
          showAlert("error", res?.data?.error ?? "There was an error uploading file. Please try again.");
        }
      }
      else if(dealAllocationFlag === "paa-taa-saa-strategy-file"){
        let res = await uploadPAATAASAAStrategy(selectedFileName, selectedFile);
        if (res.status === 204 || res.status === 200) {
          document.querySelector("#pds-file-upload-input-box").files =
              new DataTransfer().files;
          // setFileUploadSuccess(true);
          showAlert("success", "File uploaded successfully!");
          setSelectedFileName("");
          setSelectedAPIName("");
        } else {
          // setFileUploadSuccess(false);
          showAlert("error", res?.data?.error ?? "There was an error uploading file. Please try again.");
        }
      }
    }
  };
  const downloadFileByURL = async (file_url) => {
    saveAs(file_url);
  };

  const fileDownloadByDate = async () => {
    setDateErrFlag(false);
    let selDate = document.getElementById("dateInput")?.value;
    setSelectedDate(document.getElementById("dateInput")?.value);
    if (selectedAPIName === "" || selDate === "") {
      setDownloadAllInput(true);
    } else {
      setDownloadAllInput(false);
    }
    let date, month, year;
    if (selDate !== "") {
      let date_arr = selDate?.split("/");
      date = date_arr[1];
      month = date_arr[0];
      year = date_arr[2];
      const res = await alphaForecastGET(year + "-" + month + "-" + date);
      const file_url = res.data.file_url;
      file_url === undefined ? setFileNotFound(true) : setFileNotFound(false);
      if (res.status === 200) {
        //File URL there
        setFileDownloadMessage(
          "File has been successfully downloaded to downloads folder"
        );
        await downloadFileByURL(file_url);
        setFileDownloadSuccess(true);
      } else {
        setFileDownloadMessage(res.data.error);
        setFileDownloadSuccess(false);
      }
      setTimeout(
        function () {
          setFileDownloadSuccess(null);
        }.bind(this),
        7000
      );
    } else {
      setDateErrFlag(true);
    }
  };

  const checkRegexOnRadioChange = (dealFlagFunc, fileName) => {
    if (selectedFileName !== "") {
      let regex;
      if (dealFlagFunc === null) {
        //TAA Segment regex
        regex = new RegExp(
          "^([A-Za-z]*_)(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])([0-9]{2}).(csv|CSV)"
        );
      } else if (dealFlagFunc === "target_volume_assumption") {
        //TVA Segment regex
        regex = new RegExp(
          "(^target_volume_assumption_([0-9]{4})_(0[1-9]|1[012])_(0[1-9]|[12][0-9]|3[01]).(csv|CSV)$)"
        );
      } else if (dealFlagFunc === "wal_segments_assumptions") {
        //WAL regex
        regex = new RegExp(
          "(^wal_segments_assumptions_([0-9]{4})_(0[1-9]|1[012])_(0[1-9]|[12][0-9]|3[01]).(csv|CSV)$)"
        );
      } else if (dealFlagFunc === "subclass_level_assumptions") {
        //ASLA regex
        regex = new RegExp(
          "(^subclass_level_assumptions_([0-9]{4})_(0[1-9]|1[012])_(0[1-9]|[12][0-9]|3[01]).(csv|CSV)$)"
        );
      } else if (dealFlagFunc === "purchases_to_level4") {
        //Purchase regex
        regex = new RegExp(
          "(^purchases_to_level4_([0-9]{4})_(0[1-9]|1[012])_(0[1-9]|[12][0-9]|3[01]).(csv|CSV)$)"
        );
      } else if(dealFlagFunc === "paa-taa-saa-strategy-file"){
        regex = new RegExp(
            "(^paa_taa_saa_(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])([0-9]{2}).(csv|CSV))"
        );
      }
      const regexRes = regex.test(fileName);
      setFileErrFlag(!regexRes);
      console.log("Deal Allocation flag", dealAllocationFlag);
      if (!regexRes) {
        document
          .querySelector(".pds-fileUpload")
          .FileUpload.addErrorToFile(0, "File name not as per format");
      } else {
        document
          .querySelector(".pds-fileUpload")
          .FileUpload.removeErrorFromFile(0);
      }
    }
  };

  const fileInputChange = async (event) => {
    let regex;
    if (dealAllocationFlag === null) {
      //TAA Segment regex
      regex = new RegExp(
        "^([A-Za-z]*_)(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])([0-9]{2}).(csv|CSV)"
      );
    } else if (dealAllocationFlag === "target_volume_assumption") {
      //TVA Segment regex
      regex = new RegExp(
        "(^target_volume_assumption_([0-9]{4})_(0[1-9]|1[012])_(0[1-9]|[12][0-9]|3[01]).(csv|CSV)$)"
      );
    } else if (dealAllocationFlag === "wal_segments_assumptions") {
      //WAL regex
      regex = new RegExp(
        "(^wal_segments_assumptions_([0-9]{4})_(0[1-9]|1[012])_(0[1-9]|[12][0-9]|3[01]).(csv|CSV)$)"
      );
    } else if (dealAllocationFlag === "subclass_level_assumptions") {
      //ASLA regex
      regex = new RegExp(
        "(^subclass_level_assumptions_([0-9]{4})_(0[1-9]|1[012])_(0[1-9]|[12][0-9]|3[01]).(csv|CSV)$)"
      );
    } else if (dealAllocationFlag === "purchases_to_level4") {
      //Purchase regex
      regex = new RegExp(
        "(^purchases_to_level4_([0-9]{4})_(0[1-9]|1[012])_(0[1-9]|[12][0-9]|3[01]).(csv|CSV)$)"
      );
    } else if(dealAllocationFlag === "paa-taa-saa-strategy-file"){
      regex = new RegExp(
          "(^paa_taa_saa_(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])([0-9]{2}).(csv|CSV))"
      );
    }
    const regexRes = regex.test(event.target.files[0].name);
    setFileErrFlag(!regexRes);
    const file = event.target.files[0];
    setSelectedFileName(event.target.files[0].name);
    setSelectedFile(file);
    setFileSelect(false);
    if (!regexRes) {
      document
        .querySelector(".pds-fileUpload")
        .FileUpload.addErrorToFile(0, "The uploaded file is not in the expected format.");
    } else {
      document
        .querySelector(".pds-fileUpload")
        .FileUpload.removeErrorFromFile(0);
    }
  };

  if(!isUpdateAllowed){
    return (
        <Unauthorized />
    )
  }

  return (
    <>
      <table style={{ width: "100%" }}>
        <tr>
          {downloadItems.length > 0 && (
            <td
              style={{
                width: "40%",
                height: "100%",
                paddingRight: "100px",
                paddingLeft: "20px",
                verticalAlign: "top",
              }}
            >
              <div
                className="pds-selectionCard"
                style={{ textAlign: "center" }}
              >
                <p>Download File</p>
                {downloadItems.map((e) => {
                  return (
                    <div className="pds-radio">
                      <input
                        name="pdsRadioDownload"
                        type="radio"
                        id="pdsRadioOptionsDownload"
                        value={e.apiName}
                        onClick={() => {
                          handleAPISelectionChange("download", e.apiName);
                        }}
                      />
                      <label
                        className="pds-label"
                        htmlFor="pdsRadioOptionsDownload"
                        style={{
                          paddingTop: "25px",
                          paddingBottom: "25px",
                          textAlign: "left",
                        }}
                      >
                        {e.apiName}
                      </label>
                    </div>
                  );
                })}

                <br />
                <p style={{ textAlign: "left" }}>Please select fill date</p>
                <div>
                  <div className="input-group date">
                    <input
                      id="dateInput"
                      type="text"
                      maxLength={14}
                      placeholder="MM/DD/YYYY"
                      data-input-mask-type="date"
                      className="form-control horizon-date-picker horizon-input-mask"
                      data-provide="horizon-date-picker"
                      value={selectedDate}
                      onMouseLeave={() => {
                        setSelectedDate(
                          document.getElementById("dateInput")?.value
                        );
                        if (selectedDate === "") {
                          setDateErrFlag(true);
                        } else {
                          setDateErrFlag(false);
                        }
                      }}
                    />
                    <span className="input-group-addon">
                      <i className="fa fa-calendar"></i>
                    </span>
                  </div>
                  <br />
                </div>
                <br />
                <br />
                {downloadAllInput && <div>Please enter all details</div>}
                <br />
                <br />
                <button
                  className="pds-button pds-button-primary pds-button-icon-left"
                  style={{
                    marginTop: "20px",
                    marginBottom: "10px",
                  }}
                  onClick={() => {
                    fileDownloadByDate();
                  }}
                >
                  <i data-feather="download" aria-hidden="true"></i> Download
                  File
                </button>
                {fileDownloadSuccess && (
                  <div className="pds-alert pds-alert-success">
                    <div className="pds-card-section">
                      <div className="pds-alert-content" role="alert">
                        <span className="sr-only">Success. </span>
                        {fileDownloadMessage}
                      </div>
                    </div>
                  </div>
                )}
                {!fileDownloadSuccess && fileDownloadSuccess !== null && (
                  <div className="pds-alert pds-alert-error">
                    <div className="pds-card-section">
                      <div className="pds-alert-content" role="alert">
                        <span className="sr-only">Error. </span>
                        {fileDownloadMessage}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </td>
          )}
          {uploadItems.length > 0 && (
            <td
              style={{
                width: "40%",
                height: "100%",
                paddingLeft: "50px",
                paddingRight: "20px",
                marginRight: "15px",
                verticalAlign: "top",
              }}
            >
              <div
                className="pds-selectionCard"
                style={{ textAlign: "center" }}
              >
                <p>Upload File</p>
                {uploadItems.map((e) => {
                  return (
                    <div className="pds-radio">
                      <input
                        name="pdsRadioUpload"
                        type="radio"
                        id={e.apiName}
                        value={e.apiName}
                        onClick={() => {
                          handleAPISelectionChange("upload", e.apiName);
                        }}
                      />
                      <label
                        className="pds-label"
                        htmlFor={e.apiName}
                        style={{
                          paddingTop: "25px",
                          paddingBottom: "25px",
                          textAlign: "left",
                        }}
                      >
                        {e.apiName}
                      </label>
                    </div>
                  );
                })}
                {/* {apiCategory === "TAA by Segment" && (
                  <a href={Sample1}>
                    Download Sample Template For TAA By Segment
                  </a>
                )}
                {apiCategory === "Deal Allocation" && (
                  <a href={Sample2}>
                    Download Sample Template For WAL by segment
                  </a>
                )}
                <br />
                <br />
                {apiCategory === "Deal Allocation" && (
                  <a href={Sample3}>
                    Download Sample Template For Target Volume Assumptions
                  </a>
                )}
                <br />
                <br />
                {apiCategory === "Deal Allocation" && (
                  <a href={Sample3}>
                    Download Sample Template For Asset Subclass level
                    Assumptions
                  </a>
                )} */}
                <div>
                  <div
                    className="pds-fileUpload"
                    data-drop-zone-configuration="box"
                    style={{ textAlign: "left" }}
                  >
                    <label
                      className="pds-label"
                      htmlFor="pds-file-upload-input-box"
                    >
                      Attach files
                    </label>
                    {
                      fileFormat[dealAllocationFlag] ?
                          <div
                              className="pds-helperText"
                              id="pds-file-upload-helper-text-box"
                          >
                            <span>Expected file name format: {fileFormat[dealAllocationFlag] ?? ""} <a href={paa_taa_saa_MMDDYY} download="paa_taa_saa_MMDDYY.csv">Sample file</a></span>
                          </div>
                          : null
                    }
                    <input
                      type="file"
                      id="pds-file-upload-input-box"
                      name="pdsFileUploadInputBox"
                      className="sr-only"
                      aria-describedby="pds-file-upload-helper-text-box"
                      multiple
                      onChange={fileInputChange}
                    />
                    <div className="pds-fileUpload-drop-zone"></div>
                  </div>
                </div>
                {uploadAllInput && (
                  <div style={{ marginTop: "40px", paddingBottom: "40px" }}>
                    Please enter all details
                  </div>
                )}
                <button
                  className="pds-button pds-button-primary pds-button-icon-left"
                  style={{ marginBottom: "10px" }}
                  onClick={() => {
                    fileUpload();
                  }}
                >
                  <i data-feather="download" aria-hidden="true"></i> Upload File
                </button>
                <br />
                {fileUploadSuccess && (
                  <div className="pds-alert pds-alert-success">
                    <div className="pds-card-section">
                      <div className="pds-alert-content" role="alert">
                        <span className="sr-only">Success. </span>File has been
                        successfully uploaded
                      </div>
                    </div>
                  </div>
                )}

                {!fileUploadSuccess && fileUploadSuccess !== null && (
                  <div className="pds-alert pds-alert-error">
                    <div className="pds-card-section">
                      <div className="pds-alert-content" role="alert">
                        <span className="sr-only">Error. </span>
                        {fileUploadMessage}
                      </div>
                    </div>
                  </div>
                )}

                {
                  alertData.show ?
                      <div
                          className="pds-form"
                          style={{
                            paddingLeft: "40px",
                            paddingRight: "200px",
                          }}
                      >
                        <div
                            className={"pds-alert pds-alert-"+alertData.type}
                            style={{
                              position: "fixed",
                              left: "0",
                              bottom: "0",
                              right: "0",
                              marginBottom: "80px",
                              marginLeft: "20px",
                              marginRight: "20px",
                            }}
                        >
                          <div className="pds-card-section">
                            <div className="pds-alert-content" role="alert">
                              <span className="sr-only">Success. </span>
                              {alertData.message}
                            </div>
                          </div>
                        </div>
                      </div>
                      : null
                }
              </div>
            </td>
          )}
        </tr>
      </table>
    </>
  );
};
