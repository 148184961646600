import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export function userSession(state = initialState.userSession, action) {
    switch (action.type) {
        case types.SAVE_MS_GRAPH_TOKEN: {
            return { ...state, ms_access_token: action.status };
        }
        case types.SET_USER_SESSION: {
            return {
                ...state,
                clientId: action.status.clientId,
                email: action.status.email,
                isValid: action.status.isValid,
                userName: action.status.userName,
                access_token: action.status.access_token,
                id_token: action.status.id_token,
                refresh_token: action.status.refresh_token,
                isAuthenticated:action.status.isAuthenticated,
            };
        }
        default:
            return state;
    }
}

export function allUsers(state = initialState.allUsers, action) {
    switch (action.type) {
        case types.GET_ALL_USERS_MS_GRAPH_API:
            {
                return action.status.value;
            }
        default:
            return state;
    }
}