import React, {useState} from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import Login from "../login/login";
import HomePage from "../home/HomePage";
import { AlphaForecastCategories } from "../alphaForcast/alphaForecastCategories.tsx";
import { AlphaForcastAPI } from "../alphaForcast/alphaForecast.tsx";
import QuickSightDashBoards from "../quicksightDashboards/QuickSightDashBords";
import PageNotFound from "../errorPages/PageNotFound.tsx";
import { UpdateReport } from "../onPremiseReport/updateReport.tsx";
import { UpdateReportCategory } from "../onPremiseReport/updateReportCategory.tsx";
import { AddReport } from "../onPremiseReport/addReport.tsx";
import { DataTableReport } from "../onPremiseReport/dataTableReport.tsx";
import ReferenceData from "../referenceData/ReferenceData";
import Spinner from "./../common/Spinner/Spinner";
import { QuickSightSetting } from "../quickSightSetting/quickSightSetting.tsx";
import { UpdatePriority } from "../onPremiseReport/updatePriority.tsx";

const NavRoutes = (props) => {

  let [loader, setLoader] = useState(false);
  let [loadingMessage, setLoadingMessage] = useState("");

  const showLoader = (loaderFlag, loaderMessage) =>{
    setLoader(loaderFlag);
    setLoadingMessage(loaderMessage);
  }

  return (
    <>
      {loader ?
          <Spinner loading={loader} loadingMessage={loadingMessage} />
          : null
      }
      <Switch>
        <Route path={"/login"} component={Login} />
        <PrivateRoute exact path="/" component={HomePage} />
        <PrivateRoute path="/apiCategory" component={AlphaForecastCategories} />
        <PrivateRoute path="/api" component={AlphaForcastAPI} />
        <PrivateRoute path="/quicksight" component={QuickSightDashBoards} />
        <PrivateRoute path="/onPremiseReport">
          <DataTableReport type="On Premise Reports" />
        </PrivateRoute>
        <PrivateRoute path="/applications">
          <DataTableReport type="Applications" />
        </PrivateRoute>
        <PrivateRoute path="/selfService">
          <DataTableReport type="Self-Service" />
        </PrivateRoute>
        <PrivateRoute path="/feedback">
          <DataTableReport type="Feedback" />
        </PrivateRoute>
        <PrivateRoute path="/manageReport" component={UpdateReport} />
        <PrivateRoute path="/manageCategory" component={UpdateReportCategory} />
        <PrivateRoute path="/addReport" component={AddReport} />
        {/*<PrivateRoute path="/referenceData" component={ReferenceData} showLoader={showLoader} />*/}
        <PrivateRoute path="/quickSightSetting" component={QuickSightSetting} />
        <PrivateRoute path="/updatePriority" component={UpdatePriority} />
        <PrivateRoute component={PageNotFound} />
      </Switch>
    </>
  );
};
export default NavRoutes;
