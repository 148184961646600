import React, { Component } from "react";
import { Auth, Hub } from "aws-amplify";
import Logo from "../../assets/Principal_Logo.svg";
import Redirect from "./Redirect";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loginFlag } from "../../redux/actions/login.action";
import { withRouter } from "react-router-dom";
import "./login.css";
// import { Spinner } from '../../components/Common/Spinner/Spinner';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authState: "",
      user: null,
      customState: null,
      token: "",
      user_name: "",
      user_email: "",
      user_grp: "",
      flag: false,
    };
    this.setOverlayFlag = this.setOverlayFlag.bind(this);
  }
  componentDidMount() {
    const { history } = this.props;
    this.props.loginFlagAction(false);
    localStorage.removeItem("login");

    if (
      localStorage.getItem("exp") !== null &&
      new Date(localStorage.getItem("exp") * 1000) > new Date()
    ) {
      localStorage.setItem("login", "into");
      this.props.loginFlagAction(true);
      this.setOverlayFlag(false);
      history.push("/");
    } else {
      localStorage.removeItem("startpage");
      this.setOverlayFlag(false);
    }

    Hub.listen("auth", ({ payload: { event, data } }) => {
      //localStorage.setItem('startpage','startpage');
      switch (event) {
        case "signIn":
          this.setState({ user: data });
          this.setOverlayFlag(true);

          Auth.currentAuthenticatedUser({ bypassCache: false })

            .then((user) => {
              //localStorage.setItem('accessToken', user.signInUserSession.idToken.jwtToken)
              localStorage.setItem(
                "accessToken",
                user.signInUserSession.accessToken.jwtToken
              );
              localStorage.setItem(
                "exp",
                user.signInUserSession.accessToken.payload.exp
              );
              localStorage.setItem("login", "into");
              //localStorage.setItem('group', group)
              localStorage.setItem("userName", user.attributes.name);
              //console.log(user.attributes.name)
              let userGroup = user.signInUserSession.accessToken.payload["cognito:groups"];
              localStorage.setItem("group", userGroup);
              localStorage.setItem("userEmail", user.attributes.email);
              this.setOverlayFlag(false);
              this.props.loginFlagAction(true);

              let hasUpdateAccess = userGroup.indexOf("GA_LAUNCHPAD_UPDATE_GROUP")>=0;
              localStorage.setItem("hasUpdateAccess", hasUpdateAccess);

              window.location.href = window.location.origin+"/v2/"

              // if (user.signInUserSession.accessToken.payload["cognito:groups"].includes('DDM_UPDATE_GROUP')) {
              //   this.state.user_grp = "DDM_UPDATE_GROUP"
              //   localStorage.setItem('accessToken', user.signInUserSession.accessToken.jwtToken)
              //   localStorage.setItem("exp",user.signInUserSession.accessToken.payload.exp)
              //   localStorage.setItem('login','into')
              //   localStorage.setItem('group', 'DDM_UPDATE_GROUP')
              //   localStorage.setItem('userName',user.attributes.name)
              //   localStorage.setItem('userEmail',user.attributes.email)
              //   this.props.loginFlagAction(true)
              //   this.setOverlayFlag(false)
              //   history.push('/react')

              // } else {
              //   for (const group of user.signInUserSession.accessToken.payload["cognito:groups"]) {
              // if (group === "DDM_VIEW_GROUP") {
              //   this.state.user_grp = "DDM_VIEW_GROUP"
              //   localStorage.setItem('accessToken', user.signInUserSession.accessToken.jwtToken)
              //   localStorage.setItem("exp",user.signInUserSession.accessToken.payload.exp)
              //   localStorage.setItem('login','into')
              //   localStorage.setItem('group', group)
              //   localStorage.setItem('userName',user.attributes.name)
              //   localStorage.setItem('userEmail',user.attributes.email)
              //   this.props.loginFlagAction(true)
              //   this.setOverlayFlag(false)
              //   history.push('/react')
              // }
              // else if (group === "DDM_UPDATE_GROUP") {
              //   this.state.user_grp = "DDM_UPDATE_GROUP"
              //   localStorage.setItem('accessToken', user.signInUserSession.accessToken.jwtToken)
              //   localStorage.setItem("exp",user.signInUserSession.accessToken.payload.exp)
              //   localStorage.setItem('login','into')
              //   localStorage.setItem('group', group)
              //   localStorage.setItem('userName',user.attributes.name)
              //   localStorage.setItem('userEmail',user.attributes.email)
              //   this.props.loginFlagAction(true)
              //   this.setOverlayFlag(false)
              //   history.push('/react')
              // }

              //   }
              // }

              this.setState({
                authState: "signedIn",
                token: user.signInUserSession.accessToken.jwtToken,
                user_name: user.attributes.name,
                user_email: user.attributes.email,
              });
              localStorage.removeItem("startpage");
            })
            .catch((e) => {
              //console.log(e);
              this.setState({ authState: "unAuthorized" });
            });
          break;
        case "signOut":
          this.setState({ authState: "signOut" });
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
        case "customState_failure":
          let retryCount = this.getParameterByName("retryLogin") ?? 0;
          retryCount = parseInt(retryCount)+1;
          this.setState({ authState: "unAuthorized" });
          if(retryCount < 3){
            window.location.href = window.location.href + "?retryLogin="+retryCount;
          }
          break;
        default:
          this.setState({ authState: null });
      }
    });
  }

  setOverlayFlag(value) {
    this.setState({ flag: value });
  }

  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  render() {
    return (
      <>
        {localStorage.getItem("startpage") !== null ? (
          <div></div>
        ) : (
          <div>
            {this.state.flag ? (
              <>
                <div>Loading...</div>
              </>
            ) : (
              <div className="login">
                <div className="login_form">
                  <img
                    src={Logo}
                    style={{ marginLeft: "9%" }}
                    alt="principal logo"
                  />
                  <br />
                  <Redirect locationSearch={this.props?.location?.state?.from?.search} />
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loginFlagAction: loginFlag,
    },
    dispatch
  );

export default withRouter(connect(null, mapDispatchToProps)(Login));
