import React, { FC, useEffect } from "react";

export const AlphaForecastCategories: FC = (props) => {
  useEffect(() => {
    document.title = "Upload/Download";
    const script = document.createElement("script");
    script.src =
      "https://www.principalcdn.com/css/principal-design-system/card/v25/card.min.js";
    script.async = true;
    document.body.appendChild(script);
    const coreScript = document.createElement("script");
    coreScript.src =
      "https://www.principalcdn.com/css/principal-design-system/core/v20/core.min.js";
    coreScript.async = true;
    document.body.appendChild(coreScript);
  });

  return (
    <div style={{ textAlign: "center" }}>
      <div
        className="pds-card pds-card-active"
        style={{ marginRight: "20px", marginTop: "20px" }}
      >
        <div className="pds-card-section">
          <div>
            <a
              href="/api"
              onClick={() => {
                sessionStorage.setItem("apiCategory", "TAA by Segment");
              }}
            >
              <h2 className="pds-card-title" style={{ textAlign: "left", marginBottom: "0" }}>
              PAA-SAA and Spreads by Segment Load to ALFA file
              </h2>
            </a>
          </div>
        </div>
      </div>
      <div
        className="pds-card pds-card-active"
        style={{ marginRight: "20px", marginTop: "20px" }}
      >
        <div className="pds-card-section">
          <div>
            <a
              href="/api"
              onClick={() => {
                sessionStorage.setItem("apiCategory", "PAA-TAA-SAA strategy");
              }}
            >
              <h2 className="pds-card-title" style={{ textAlign: "left", marginBottom: "0" }}>
                PAA-SAA by Segment Load to Reservoir file
              </h2>
            </a>
          </div>
        </div>
      </div>
      <div
        className="pds-card pds-card-active"
        style={{ marginRight: "20px", marginTop: "20px" }}
      >
        <div className="pds-card-section">
          <div>
            <a
              href="/api"
              onClick={() => {
                sessionStorage.setItem("apiCategory", "Deal Allocation");
              }}
            >
              <h2 className="pds-card-title" style={{ textAlign: "left", marginBottom: "0" }}>
                Assumptions
              </h2>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
