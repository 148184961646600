import React, {useEffect, useState} from "react";
import "./GlobalSearch.css";
import axios from "axios";
import excelLogo from "../../assets/images/excel_wiki.svg";
import powerBILogo from "../../assets/images/powerbi_wiki.svg";
import powerPointLogo from "../../assets/images/powerpoint_wiki.svg";

const GlobalSearch = () =>{
    const [searchList, setSearchList] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [keyword, setKeyword] = useState("");

    useEffect(()=>{
        getOnPremList("On Premise Reports").then((response)=>{
            let newSearchList = [];
            Object.values(response.data).forEach((list)=>{
                list.forEach((item)=>{
                    newSearchList.push({
                        reportName: item.reportName,
                        reportType: item.reportType,
                        reportURL: item.reportURL
                    });
                });
            });
            setSearchList(newSearchList);

            getDashboards("Prod").then((prodData)=>{
                prodData.forEach((item)=>{
                    newSearchList.push({
                        reportName: item["dashboard_name"],
                        reportType: "qs",
                        reportURL: "/quicksight?dashboard_id=" + item.dashboard_id+"&dashboard_type="+item["dashboard_type"]
                    })
                })

                getDashboards("Dev").then((devData)=>{
                    devData.forEach((item)=>{
                        newSearchList.push({
                            reportName: item["dashboard_name"],
                            reportType: "qs",
                            reportURL: "/quicksight?dashboard_id=" + item.dashboard_id+"&dashboard_type="+item["dashboard_type"]
                        })
                    })
                    setSearchList(newSearchList);

                    getOnPremList("Applications").then((response)=> {
                        Object.values(response.data).forEach((list) => {
                            list.forEach((item) => {
                                newSearchList.push({
                                    reportName: item.reportName,
                                    reportType: item.reportType,
                                    reportURL: item.reportURL
                                });
                            });
                        });
                        setSearchList(newSearchList);

                        getOnPremList("Self-Service").then((response)=> {
                            Object.values(response.data).forEach((list) => {
                                list.forEach((item) => {
                                    newSearchList.push({
                                        reportName: item.reportName,
                                        reportType: item.reportType,
                                        reportURL: item.reportURL
                                    });
                                });
                            });
                            setSearchList(newSearchList);

                            getOnPremList("Feedback").then((response)=> {
                                Object.values(response.data).forEach((list) => {
                                    list.forEach((item) => {
                                        newSearchList.push({
                                            reportName: item.reportName,
                                            reportType: item.reportType,
                                            reportURL: item.reportURL
                                        });
                                    });
                                });
                                setSearchList(newSearchList);
                            });
                        });
                    });
                })
            })
        })

    }, []);

    const getOnPremList = async (type) =>{
        const res = axios.get(process.env.REACT_APP_ONPREM_LIST + type, {
                headers: {
                    "Content-Type": "text/csv",
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.getItem("accessToken"),
                },
            })

        const { data } = await res;
        return data;
    }

    const getDashboards = async (dropdownType) =>{
        const res = await axios.post(process.env.REACT_APP_QUICKSIGHT_GET_DASHBOARD_LIST,
                { dashboard_select: dropdownType },
                {
                    headers: {
                        "Content-Type":
                            "application/x-www-form-urlencoded; charset=UTF-8",
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.getItem("accessToken"),
                    },
                }
            );
        const { data } = await res;
        return data;
    }

    const filterList = (keyword) =>{
        setKeyword(keyword);
        let newFilteredList = [];
        if(keyword && keyword.length){
            let keywordLowercase = keyword.toLowerCase();
            searchList.forEach((item)=>{
                let reportNameLowercase = item.reportName.toLowerCase();
                if(reportNameLowercase.indexOf(keywordLowercase)>=0){
                    newFilteredList.push(item);
                }
            });
        }
        setFilteredList(newFilteredList)
    }

    const excelIcon = (
        <img src={excelLogo} title="Excel" height={15} width={15} alt="Excel" />
    );
    const powerBIIcon = (
        <img src={powerBILogo} title="Excel" height={15} width={15} alt="powerBI" />
    );

    const fileIcon = (
        <i data-feather="file" aria-hidden="true" height={15} width={15}></i>
    );

    const powerPointIcon = (
        <img
            src={powerPointLogo}
            title="Excel"
            height={15}
            width={15}
            alt="PowerPoint"
        />
    );

    const quicksightIcon = (
        <svg height="15" width="15" preserveAspectRatio="xMidYMid" viewBox="0 0 256 256"
             xmlns="http://www.w3.org/2000/svg"><path d="m19.2 19.2h217.6v38.4l-108.8 108.8v-89.6l-108.8 108.8zm-6.4-19.2c-7.68 0-12.8 5.12-12.8 12.8v230.4c0 7.68 5.12 12.8 12.8 12.8h230.4c7.68 0 12.8-5.12 12.8-12.8v-230.4c0-7.68-5.12-12.8-12.8-12.8z"
                                                      fill="#00b7f4"/>
        </svg>
    )

    const openURL = (url, name) =>{
        if(name){
            localStorage.setItem("selected_dashboard",name);
        }
        let newTab = window.open();
        newTab.opener = null;
        newTab.location = url;
    }

    return(
        <div className="pds-form global-search-div">
            <div className="global-search-inner">
                <div className="pds-form-field">
                    <div className="pds-labeledInput" style={{ marginTop: "30px" }}>
                        {/*<label htmlFor="pdsForm-labeledInputId" className="pds-label">*/}
                        {/*    Search*/}
                        {/*</label>*/}

                        <input
                            data-testid="global-search-input"
                            aria-label=""
                            type="text"
                            value={keyword}
                            onChange={(event)=>filterList(event.target.value)}
                            placeholder="Search"
                        />
                    </div>
                </div>
                <br/>
                <div className="result-div" data-testid="global-search-result">
                    {
                        filteredList.length ?
                        filteredList.map((item)=>{
                            return(
                                <a className="result-item" onClick={()=>openURL(item.reportURL, item.reportName)} target="_blank">
                                    {item.reportType === "e"
                                        ? excelIcon
                                        : item.reportType === "pb"
                                            ? powerBIIcon
                                            : item.reportType === "pp"
                                                ? powerPointIcon
                                                : item.reportType === "folder"
                                                    ? fileIcon
                                                    : item.reportType === "qs"
                                                        ? quicksightIcon
                                                        : ""}{" "}
                                    {item.reportName}
                                </a>
                            )
                        }) : (keyword ? <p>No results found.</p> : null)
                    }
                </div>
            </div>
        </div>
    )
}

export default GlobalSearch;