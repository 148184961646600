import React from "react";
import "./Footer.css"

const Footer = () => {
    //const activeStyle = { color: "#F15B2A" };
    return (
        // <div className="footer">
        <>
            <footer className="footer">
                <section className="footer-row util-padding-top-10 util-padding-bottom-10">
                    <nav>
                        <ul className="nav nav-inline util-margin-bottom-0">
                            <li><a href="https://www.principal.com/terms-of-use">Terms of Use</a></li>
                            <li><a href="https://www.principal.com/products-services-disclosures">Disclosures</a></li>
                            <li><a href="https://www.principal.com/privacy-policies">Privacy</a></li>
                            <li><a href="https://www.principal.com/security-policies">Security</a></li>
                            <li><a href="https://www.principal.com/about-us/our-company/policies/report-fraud-or-unethical-conduct">Report Fraud</a></li>
                            <li><a href="#placeholder">Site Map</a></li>
                        </ul>
                    </nav>
                    {/* <div className="footer-legal">
                <p>&copy; 2016, Principal Financial Services, Inc.</p>
                <p>Securities offered through Principal Securities, Inc., <a href="http://www.sipc.org">member SIPC</a></p>
            </div> */}
                </section>
            </footer>
            <div style={{width: "100%", height: "50px"}}>

            </div>
        </>
        // </div>    
    );
};


export default Footer;