import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export function userGroupChartReducer(
  state = initialState.groupChartDetails,
  action
) {
  switch (action.type) {
    case types.SAVE_GROUP: {
      if (action.status === "True") {
        let tempData = {
          group_owner_name: action.userData.username,
          group_name: action.userData.groupname,
          graph_name: [],
          graphs: [],
          members: [],
          member_name: [
            {
              member_name: action.userData.username,
              member_added_date: new Date().toDateString(),
            },
          ],
          creation_date: new Date().toDateString(),
        };
        return [...state, tempData];
      }
      return state;
    }
    case types.SAVE_CHART_TO_GROUP: {
      if (action.status === "True") {
        let tempData = {
          graph_date: action.userData.graphdetails.graph_date,
          graph_name: action.userData.graphdetails.graph_name,
          graph_owner: action.userData.graphdetails.graph_owner,
        };
        return state.map((value) => {
          if (value.group_name === action.userData.groupname) {
            return {
              ...value,
              graphs: [
                ...value.graphs,
                action.userData.graphdetails.graph_name,
              ],
              graph_name: [...value.graph_name, tempData],
            };
          }
          return value;
        });
      }
      return state;
    }
    case types.SAVE_MEMBER_TO_GROUP: {
      if (action.status === "True") {
        let tempData = {
          member_added_date: action.userData.membername.member_added_date,
          member_name: action.userData.membername.member_name,
        };
        return state.map((value) => {
          if (value.group_name === action.userData.groupname) {
            return {
              ...value,
              members: [
                ...value.members,
                action.userData.membername.member_name,
              ],
              member_name: [...value.member_name, tempData],
            };
          }
          return value;
        });
      }
      return state;
    }
    case types.REMOVE_MEMBER_FROM_GROUP: {
      if (action.status === "True") {
        return state.map((value) => {
          if (value.group_name === action.userData.groupname) {
            return {
              ...value,
              members: [
                ...value.members.filter(
                  (d) => d !== action.userData.membername
                ),
              ],
              member_name: [
                ...value.member_name.filter(
                  (d) => d.member_name !== action.userData.membername
                ),
              ],
            };
          }
          return value;
        });
      }
      return state;
    }
    case types.CHNAGE_MEMBER_PERMISSION: {
      if (action.status === "True") {
        return state.map((value) => {
          if (value.group_name === action.userData.groupname) {
            return {
              ...value,
              member_name: [
                ...value.member_name.map((d) => {
                  if (d.member_name === action.userData.membername) {
                    return {
                      ...d,
                      ["member_permission"]: action.userData.memberpermission,
                    };
                  }
                  return d;
                }),
              ],
            };
          }
          return value;
        });
      }
      return state;
    }
    case types.REMOVE_CHART_FROM_GROUP: {
      if (action.status === "True") {
        return state.map((value) => {
          if (value.group_name === action.userData.groupname) {
            return {
              ...value,
              graphs: [
                ...value.graphs.filter((d) => d !== action.userData.graphname),
              ],
              graph_name: [
                ...value.graph_name.filter(
                  (d) => d.graph_name !== action.userData.graphname
                ),
              ],
            };
          }
          return value;
        });
      }
      return state;
    }
    case types.REMOVE_GROUP: {
      if (action.status === "True") {
        return state.filter((d) => d.group_name !== action.userData.groupname);
      }
      return state;
    }
    case types.GET_CHART_GROUP_DETAILS: {
      return action.status;
    }
    default:
      return state;
  }
}
