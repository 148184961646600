export const LOGIN_FLAG="LOGIN_FLAG"
export const QUICK_LINKS="QUICK_LINKS"


export function loginFlag(flag){
    return{
        type:LOGIN_FLAG,
        flag:flag
    }
}
export const handleQuickLinks = (mode, recType) =>{
    return{
        type:QUICK_LINKS,
        mode:mode
        //isNewRecordType:recType
    }
} 
