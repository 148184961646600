import React from "react";

const PageNotFound = () => (
  <div className="pds-notFoundError" style={{ height: "100%" }}>
    <div className="pds-notFoundError-background" style={{ height: "100%" }}>
      <section
        className="pds-imgCarrollTheLibrarian"
        style={{ height: "100%" }}
      >
        <div className="pds-typography-center">
          <div className="pds-util-padding-horz-10">
            <h1
              className="pds-typography-h2 pds-util-margin-top-20 pds-util-margin-bottom-10"
              style={{ textAlign: "center" }}
            >
              We're sorry.
            </h1>
            <p
              className="pds-util-margin-top-0 pds-util-margin-bottom-10"
              style={{ textAlign: "center" }}
            >
              We've looked everywhere and can't seem to find the page you're
              looking for.
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
);

export default PageNotFound;
