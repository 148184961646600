import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export function tickerReducer(state = initialState.tickers, action){
    switch(action.type){
        case types.LOAD_TICKERS_SUCCESS:
            return action.tickers;
        default:
            return state;
    }
}


export function tickerDataReducer(state = initialState.tickersData, action) {
    switch (action.type) {
        case types.GET_TICKER_DATA_SUCCESS:
            return {...state, [action.ticker]:action.tickersData };
        default:
            return state;
    }
}