import axios from "axios";
import React, { FC, useEffect, useState } from "react";
import { getAllReports, updateDeleteOnPrem } from "../../api/apiUtils";
import { Unauthorized } from "../errorPages/Unauthorized.tsx";

export const UpdateReport: FC = () => {
  type arrType = {
    id: string;
    report_name: string;
    reportURL: string;
    reportType: string;
    columName: string;
  };
  const [arrState, setArrState] = useState([]);
  const [originalArr, setOriginalArr] = useState([]);
  const [arrHeaders, setArrHeaders] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [updateArray, setUpdateArray] = useState([]);
  const [deleteArray, setDeleteArray] = useState([]);
  const [updateButtonState, setUpdateButtonState] = useState(true);
  const [deleteButtonState, setDeleteButtonState] = useState(true);
  const [resetButtonState, setResetButtonState] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [successMsg, setSuccessMsg] = useState();
  const [failureMsg, setFailureMsg] = useState();
  const [arrOnPrem, setArrOnPrem] = useState();
  const [arrSelfService, setArrSelfService] = useState();
  const [arrApplications, setArrApplications] = useState();
  const [arrFeedback, setArrFeedback] = useState();
  let isUpdateAllowed = localStorage.getItem("hasUpdateAccess");
  isUpdateAllowed = JSON.parse(isUpdateAllowed);

  useEffect(() => {
    document.title = "Manage Report";
    const script = document.createElement("script");
    script.src =
      "https://www.principalcdn.com/css/principal-design-system/pds/latest/pds.min.js";
    script.async = true;
    document.body.appendChild(script);
    const inputScript = document.createElement("script");
    inputScript.src =
      "https://www.principalcdn.com/css/principal-design-system/helperInput/v8/helperInput.min.js";
    inputScript.async = true;
    document.body.appendChild(inputScript);
    const alertScript = document.createElement("script");
    alertScript.src =
      "https://www.principalcdn.com/css/principal-design-system/alert/v21/alert.min.js";
    alertScript.async = true;
    document.body.appendChild(alertScript);
    // const dataTableScript = document.createElement("script");
    // dataTableScript.src =
    //   "https://www.principalcdn.com/css/principal-design-system/dataTable/v13/dataTable.min.js";
    // dataTableScript.async = true;
    // document.body.appendChild(dataTableScript);
    getRecords();
    getAllCategoryByMenu();
  }, []);

  useEffect(() => {
    let temp = [];
    if (searchText !== "") {
      arrState.forEach((e) => {
        //Change Report type and menu name as per type set in code
        if (
          (e.columName &&
            e.columName.toLowerCase().includes(searchText.toLowerCase())) ||
          (e.menuName &&
            e.menuName.toLowerCase().includes(searchText.toLowerCase())) ||
          (e.reportName &&
            e.reportName.toLowerCase().includes(searchText.toLowerCase())) ||
          (e.reportType &&
            e.reportType.toLowerCase().includes(searchText.toLowerCase())) ||
          (e.reportURL &&
            e.reportURL.toLowerCase().includes(searchText.toLowerCase()))
        ) {
          temp.push(e);
        }
        // if (e.ColumnName.includes(searchText)) {
        //   temp.push(e);
        // }
      });
      setArrState(temp);
    } else {
      setArrState(originalArr);
    }
  }, [searchText]);

  const getRecords = () => {
    let arr = [];
    axios
      .get(process.env.REACT_APP_ONPREM_LIST, {
        headers: {
          "Content-Type": "text/csv",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        setArrHeaders(res.data.headers);
        Object.keys(res.data.data).forEach(function (key) {
          for (let i = 0; i < res.data.data[key].length; i++) {
            arr.push(res.data.data[key][i]);
          }
        });
        setArrState(arr);
        setOriginalArr(arr);
      })
      .catch((e) => {
        return e;
      });
  };

  const getAllCategoryByMenu = async () => {
    //TODO optimize this with one api call
    let arrOnPrem = [];
    let arrFeedback = [];
    let arrService = [];
    let arrApplications = [];
    let arrPrem = await fetchReportCategories("On Premise Reports");
    let arrFeed = await fetchReportCategories("Feedback");
    let arrApp = await fetchReportCategories("Applications");
    let arrSelf = await fetchReportCategories("Self-Service");

    arrPrem.map((a) => {
      arrOnPrem.push(a.columnName);
    });
    arrFeed.map((a) => {
      arrFeedback.push(a.columnName);
    });
    arrApp.map((a) => {
      arrApplications.push(a.columnName);
    });
    arrSelf.map((a) => {
      arrService.push(a.columnName);
    });

    setArrOnPrem(arrOnPrem);
    setArrFeedback(arrFeedback);
    setArrApplications(arrApplications);
    setArrSelfService(arrService);
  };

  const updateRecords = (event, record, type) => {
    //Logic to update record goes here

    // event.target.style.backgroundColor = "yellow";
    let updateArr = updateArray.slice();
    let updateArrIndex = updateArr.findIndex((x) => x.id === record.id);
    if (updateArrIndex !== -1) {
      //Item already exists
      if (type === "Report Menu") {
        updateArr[updateArrIndex].menuName = event.target.value;
      } else if (type === "Report Category") {
        updateArr[updateArrIndex].columName = event.target.value;
      } else if ("Report Type") {
        updateArr[updateArrIndex].reportType = event.target.value;
      }
    } else {
      if (type === "Report Menu") {
        record.menuName = event.target.value;
      } else if (type === "Report Category") {
        record.columName = event.target.value;
      } else if ("Report Type") {
        record.reportType = event.target.value;
      }
      updateArr.push(record);
    }
    setUpdateArray(updateArr);
    if (updateArr.length > 0) {
      setDeleteButtonState(true);
      setUpdateButtonState(false);
      setResetButtonState(false);
    }
  };

  const fetchReportCategories = async (menuName) => {
    let data = {
      action: "list",
      menuName: menuName,
    };

    return axios
      .post(process.env.REACT_APP_REPORT_CATEGORY, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        return res.data.data[menuName];
      })
      .catch((err) => {
        return err;
      });
  };

  const stateUpdate = (event, record, type) => {
    let index = arrState.findIndex((x) => x.id === record.id);
    //Updating existing array
    if (index !== -1) {
      let temp = arrState.slice();
      if (type === "report_name") {
        temp[index].reportName = event.target.value;
        setArrState(temp);
      } else {
        temp[index].reportURL = event.target.value;
        setArrState(temp);
      }
    }
    // event.target.style.backgroundColor = "yellow";
    let updateArr = updateArray.slice();
    let updateArrIndex = updateArr.findIndex((x) => x.id === record.id);
    if (updateArrIndex !== -1) {
      //Item already exists
      if (type === "report_name") {
        updateArr[updateArrIndex].reportName = event.target.value;
      } else {
        updateArr[updateArrIndex].reportURL = event.target.value;
      }
    } else {
      if (type === "report_name") {
        record.reportName = event.target.value;
      } else {
        record.reportURL = event.target.value;
      }
      updateArr.push(record);
    }
    setUpdateArray(updateArr);
    if (updateArr.length > 0) {
      setDeleteButtonState(true);
      setUpdateButtonState(false);
      setResetButtonState(false);
    }
  };

  const deleteRecords = (record) => {
    let delArr = deleteArray.slice();
    let delArrIndex = delArr.findIndex((x) => x.id === record.id);
    // let delArrIndex = delArr.findIndex((x) => x === record.id);
    if (delArrIndex !== -1) {
      //Item already exists unchecked remove from array
      delArr.splice(delArrIndex, 1);
    } else {
      delArr.push({ id: record.id });
    }
    setDeleteArray(delArr);
    if (delArr.length > 0) {
      setDeleteButtonState(false);
      setResetButtonState(false);
    } else {
      setDeleteButtonState(true);
    }
  };

  const updateApiCall = async () => {
    let res = await updateDeleteOnPrem(updateArray, "update");
    if (res.status === 200) {
      setSuccessMsg(res.data);
      setIsSuccess(true);
      setTimeout(
        function () {
          setIsSuccess(false);
        }.bind(this),
        5000
      );
      getRecords();
      setResetButtonState(true);
      setUpdateButtonState(true);
    } else {
      setFailureMsg(res.data);
      setIsFailure(true);
      setTimeout(
        function () {
          setIsFailure(false);
        }.bind(this),
        5000
      );
    }
  };

  const deleteApiCall = async () => {
    let res = await updateDeleteOnPrem(deleteArray, "delete");
    if (res.status === 200) {
      setSuccessMsg(res.data);
      setIsSuccess(true);
      setTimeout(
        function () {
          setIsSuccess(false);
        }.bind(this),
        5000
      );
      getRecords();
    } else {
      setFailureMsg(res.data);
      setIsFailure(true);
      setTimeout(
        function () {
          setIsFailure(false);
        }.bind(this),
        5000
      );
    }
  };

  const resetApiCall = async () => {
    setUpdateArray([]);
    setUpdateButtonState(true);
    setDeleteButtonState(true);
    setResetButtonState(true);
    getRecords();
  };

  return (
    <>
      {isUpdateAllowed ? (
        <>
          <div style={{ verticalAlign: "middle" }}>
            <i
              data-feather="chevron-left"
              aria-hidden="true"
              style={{ verticalAlign: "middle" }}
            ></i>
            <a
              href="/onPremiseReport"
              className="pds-link-emphasis"
              style={{ verticalAlign: "middle" }}
            >
              back
            </a>
            <a
              className="pds-button pds-button-tertiary-border"
              style={{
                marginLeft: "30px",
                textAlign: "left",
                verticalAlign: "left",
              }}
              href="/manageCategory"
            >
              Update Report Category
            </a>
          </div>
          <div
            className="pds-dataTable"
            style={{
              overflowY: "hidden",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            <div className="pds-dataTable-header">
              <p
                aria-label="Title of the dataTable"
                className="pds-dataTable-title"
              >
                Edit Report
                <button
                  className="pds-button pds-button-tertiary-border"
                  style={{
                    marginLeft: "30px",
                    textAlign: "left",
                    verticalAlign: "left",
                  }}
                  disabled={updateButtonState}
                  onClick={() => {
                    //Function call for api
                    updateApiCall();
                  }}
                >
                  Update
                </button>
                <button
                  className="pds-button pds-button-tertiary-border"
                  style={{
                    marginLeft: "30px",
                    textAlign: "left",
                    verticalAlign: "left",
                  }}
                  disabled={deleteButtonState}
                  onClick={(e) => {
                    //Function call for api
                    deleteApiCall();
                  }}
                >
                  Delete
                </button>
                <button
                  className="pds-button pds-button-tertiary-border"
                  style={{
                    marginLeft: "30px",
                    textAlign: "left",
                    verticalAlign: "left",
                  }}
                  disabled={resetButtonState}
                  onClick={() => {
                    //Function call for api
                    resetApiCall();
                  }}
                >
                  Reset
                </button>
              </p>

              <div className="pds-searchInput" style={{ width: "350px" }}>
                <input
                  aria-label="Filter table data search bar"
                  className="pds-input-small"
                  type="search"
                  placeholder="What are you looking for?"
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
                <button
                  type="submit"
                  aria-label="Search"
                  aria-disabled="true"
                ></button>
              </div>
            </div>
            <div className="pds-dataTable-table-container">
              <table
                className="pds-table-container pds-table pds-table-bordered"
                id="pds-datatable-example"
                aria-live="polite"
                style={{ overflowY: "hidden", textAlign: "center" }}
              >
                <thead>
                  <tr>
                    <th></th>
                    <th style={{ textAlign: "center" }}>
                      Report Main Category
                    </th>
                    <th style={{ textAlign: "center" }}>Report Category</th>
                    <th style={{ textAlign: "center" }}>Report Name</th>
                    <th style={{ textAlign: "center" }}>Report Type</th>
                    <th style={{ textAlign: "center" }}>Report URL</th>
                  </tr>
                </thead>
                <tbody>
                  {arrState &&
                    arrState.map((a) => {
                      return (
                        <tr>
                          <td
                            style={{ paddingLeft: "16px" }}
                            className="pds-dataTable-table-item"
                          >
                            <div className="pds-checkbox">
                              <input
                                id="checkbox1"
                                aria-label=""
                                type="checkbox"
                                onClick={(e) => {
                                  deleteRecords(a);
                                }}
                              />
                            </div>
                          </td>

                          <td
                            className="pds-dataTable-table-item"
                            style={{ minWidth: "0px", width: "300px" }}
                          >
                            <div style={{ maxWidth: "100px;" }}>
                              <div className="pds-select">
                                <select
                                  id="selectPlaceholder"
                                  aria-label="Select placeholder"
                                  name="select-input"
                                  style={{
                                    backgroundColor: updateArray.includes(a)
                                      ? "yellow"
                                      : "white",
                                  }}
                                  onChange={(e) =>
                                    updateRecords(e, a, "Report Menu")
                                  }
                                >
                                  <option value="" hidden>
                                    Select Report Menu
                                  </option>
                                  <option
                                    value={"On Premise Reports"}
                                    selected={
                                      a.menuName === "On Premise Reports"
                                        ? true
                                        : false
                                    }
                                  >
                                    OnPremise Report
                                  </option>
                                  <option
                                    value={"Feedback"}
                                    selected={
                                      a.menuName === "Feedback" ? true : false
                                    }
                                  >
                                    Feedback
                                  </option>
                                  <option
                                    value={"Self-Service"}
                                    selected={
                                      a.menuName === "Self-Service"
                                        ? true
                                        : false
                                    }
                                  >
                                    Self-service
                                  </option>
                                  <option
                                    value="Applications"
                                    selected={
                                      a.menuName === "Applications"
                                        ? true
                                        : false
                                    }
                                  >
                                    Applications
                                  </option>
                                </select>
                              </div>
                            </div>
                          </td>
                          <td
                            className="pds-dataTable-table-item"
                            style={{ minWidth: "0px", width: "300px" }}
                          >
                            <div style={{ maxWidth: "100px;" }}>
                              <div className="pds-select">
                                <select
                                  id="selectPlaceholder"
                                  aria-label="Select placeholder"
                                  name="select-input"
                                  style={{
                                    backgroundColor: updateArray.includes(a)
                                      ? "yellow"
                                      : "white",
                                  }}
                                  onChange={(e) =>
                                    updateRecords(e, a, "Report Category")
                                  }
                                >
                                  <option value="" hidden selected>
                                    Select Report Category
                                  </option>
                                  {a.menuName === "On Premise Reports" &&
                                    arrOnPrem &&
                                    arrOnPrem.map((headerName) => {
                                      return (
                                        <option
                                          value={headerName}
                                          selected={
                                            a.columName === headerName
                                              ? true
                                              : false
                                          }
                                        >
                                          {headerName}
                                        </option>
                                      );
                                    })}
                                  {a.menuName === "Applications" &&
                                    arrApplications &&
                                    arrApplications.map((headerName) => {
                                      return (
                                        <option
                                          value={headerName}
                                          selected={
                                            a.columName === headerName
                                              ? true
                                              : false
                                          }
                                        >
                                          {headerName}
                                        </option>
                                      );
                                    })}
                                  {a.menuName === "Self-Service" &&
                                    arrSelfService &&
                                    arrSelfService.map((headerName) => {
                                      return (
                                        <option
                                          value={headerName}
                                          selected={
                                            a.columName === headerName
                                              ? true
                                              : false
                                          }
                                        >
                                          {headerName}
                                        </option>
                                      );
                                    })}
                                  {a.menuName === "Feedback" &&
                                    arrFeedback &&
                                    arrFeedback.map((headerName) => {
                                      return (
                                        <option
                                          value={headerName}
                                          selected={
                                            a.columName === headerName
                                              ? true
                                              : false
                                          }
                                        >
                                          {headerName}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                          </td>
                          <td
                            className="pds-dataTable-table-item"
                            style={{
                              width: "20px",
                            }}
                          >
                            <div>
                              <div className="pds-labeledInput">
                                <input
                                  id="helperInput"
                                  aria-label=""
                                  type="text"
                                  style={{
                                    backgroundColor: updateArray.includes(a)
                                      ? "yellow"
                                      : "white",
                                    height: "50px",
                                    paddingLeft: "7px",
                                  }}
                                  // placeholder={a.report_name}
                                  value={a.reportName}
                                  aria-describedby="helper-text-id"
                                  onChange={(e) => {
                                    stateUpdate(e, a, "report_name");
                                  }}
                                />
                              </div>
                            </div>
                          </td>
                          <td
                            className="pds-dataTable-table-item"
                            style={{ minWidth: "0px", width: "300px" }}
                          >
                            <div style={{ maxWidth: "100px;" }}>
                              <div className="pds-select">
                                <select
                                  id="selectPlaceholder"
                                  aria-label="Select placeholder"
                                  name="select-input"
                                  style={{
                                    backgroundColor: updateArray.includes(a)
                                      ? "yellow"
                                      : "white",
                                  }}
                                  onChange={(e) =>
                                    updateRecords(e, a, "Report Type")
                                  }
                                >
                                  <option value="" hidden selected>
                                    Select Report Type
                                  </option>
                                  <option
                                    value="e"
                                    selected={
                                      a.reportType === "e" ? true : false
                                    }
                                  >
                                    Excel
                                  </option>
                                  <option
                                    value="pb"
                                    selected={
                                      a.reportType === "pb" ? true : false
                                    }
                                  >
                                    PowerBI
                                  </option>
                                  <option
                                    value="pp"
                                    selected={
                                      a.reportType === "pp" ? true : false
                                    }
                                  >
                                    PowerPoint
                                  </option>
                                  <option
                                    value="folder"
                                    selected={
                                      a.reportType === "folder" ? true : false
                                    }
                                  >
                                    Folder
                                  </option>
                                </select>
                              </div>
                            </div>
                          </td>
                          <td
                            className="pds-dataTable-table-item"
                            style={{ minWidth: "700px" }}
                          >
                            <div>
                              <input
                                id="inputBasic"
                                aria-label="Input basic"
                                type="text"
                                value={a.reportURL}
                                style={{
                                  height: "50px",
                                  paddingLeft: "7px",
                                  width: "100%",
                                  backgroundColor: updateArray.includes(a)
                                    ? "yellow"
                                    : "white",
                                }}
                                onChange={(e) => {
                                  stateUpdate(e, a, "report_url");
                                }}
                                onBlur={(e) => {
                                  updateRecords(e, a, "Report URL");
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          {isSuccess && (
            <div
              className="pds-form"
              style={{
                paddingLeft: "40px",
                paddingRight: "200px",
              }}
            >
              <div
                className="pds-alert pds-alert-success"
                style={{
                  position: "fixed",
                  left: "0",
                  bottom: "0",
                  right: "0",
                  marginBottom: "80px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              >
                <div className="pds-card-section">
                  <div className="pds-alert-content" role="alert">
                    <span className="sr-only">Success. </span>
                    {successMsg}
                  </div>
                </div>
              </div>
            </div>
          )}
          {isFailure && (
            <div
              className="pds-form"
              style={{ paddingLeft: "40px", paddingRight: "200px" }}
            >
              <div
                className="pds-alert pds-alert-error"
                style={{
                  position: "fixed",
                  left: "0",
                  bottom: "0",
                  right: "0",
                  marginBottom: "80px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              >
                <div className="pds-card-section">
                  <div className="pds-alert-content" role="alert">
                    <span className="sr-only">Success. </span>
                    {failureMsg}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <Unauthorized />
      )}
    </>
  );
};
