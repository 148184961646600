export default {
  windowZIndex:1000,
  tickers: [],
  tickersData: {},
  userCharts: [],
  userSession: {},
  allUsers:[],
  groupChartDetails:[],
  apiCallsInProgress: 0
};
