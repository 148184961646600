import React, { FC, useEffect, useState } from "react";
import {
  listUserByGroup,
  searchUser,
  addUserAPI,
  deleteUserAPI,
} from "../../api/apiUtils";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { TextField } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {SolidButton, ButtonDefault} from './../guiComponents/Buttons/Button';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 600,
    height: 350,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
    backgroundColor: theme.palette.background.paper,
    borderColor: "black",
    fontSize: "18px",
  },
  circularProgress: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export const UserSetting: FC = (props) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [members, setMembers] = useState([]);
  const [allMembers, setAllMembers] = useState([]);
  const [groupMembers, setGroupMembers] = useState([]);
  const [allMembersLoading, setAllMembersLoading] = useState(false);
  const [groupMembersloading, setGroupMembersLoading] = useState(false);
  const allUsersChecked = intersection(checked, allUsers);
  const allMembersChecked = intersection(checked, members);
  const ALL_USERS = "All QuickSight Users";

  const [alertData, setAlertData] = useState({
    show: false,
    message: "",
    type: "success"
  });

  const showAlert = (type, message) =>{
    setAlertData({
      show: true,
      message: message ?? "Done",
      type: type ?? "success"
    })

    setTimeout(()=>{
      setAlertData({
        show: false,
        message: "",
        type: "success"
      })
    }, 5000)
  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const [addedUsers, setAddedUsers] = useState([]);
  const [removedUsers, setRemovedUsers] = useState([]);

  const saveChanges = () =>{
    let savePromiseArray = [];
    if(addedUsers.length){
      savePromiseArray.push(addUser(addedUsers))
    }
    if(removedUsers.length){
      savePromiseArray.push(deleteUser(removedUsers))
    }

    showAlert("information", "Saving data ...");

    Promise.all(savePromiseArray).then((responses)=>{
      return Promise.all(responses.map(function (response) {
        return response;
      }));
    }).then((data)=>{
      showAlert("success", "Data saved successfully.");

      setAddedUsers([]);
      setRemovedUsers([]);

      setAllMembers(allUsers);
      setGroupMembers(members);
    })

  }

  const discardChanges = () =>{
    setAddedUsers([]);
    setRemovedUsers([]);
    setAllUsers([...allMembers]);
    setMembers([...groupMembers]);
  }

  const handleCheckedRight = () => {
    //Adding members to group
    setMembers(allUsersChecked.concat(members));
    setAllUsers(not(allUsers, allUsersChecked));
    setChecked(not(checked, allUsersChecked));
    // addUser(allUsersChecked);
    let newAddedUsers = [...addedUsers];
    let newRemovedUsers = [...removedUsers];
    allUsersChecked.forEach((member)=>{
      if(newRemovedUsers.indexOf(member) === -1){
        newAddedUsers.push(member);
      }
      else{
        newRemovedUsers = newRemovedUsers.filter(user => user !== member);
      }
    })
    setAddedUsers(newAddedUsers);
    setRemovedUsers(newRemovedUsers);
  };

  const handleCheckedLeft = () => {
    //Removing members from group
    setAllUsers(allMembersChecked.concat(allUsers));
    setMembers(not(members, allMembersChecked));
    setChecked(not(checked, allMembersChecked));
    // deleteUser(allMembersChecked);

    let newAddedUsers = [...addedUsers];
    let newRemovedUsers = [...removedUsers];
    allMembersChecked.forEach((member)=>{
      if(newAddedUsers.indexOf(member) === -1){
        newRemovedUsers.push(member);
      }
      else{
        newAddedUsers = newAddedUsers.filter(user => user !== member);
      }
    })
    setAddedUsers(newAddedUsers);
    setRemovedUsers(newRemovedUsers);
  };

  const filterUser = (value, title) => {
    setTimeout(() => {
      if (title === ALL_USERS) {
        //Filter from all users
        setAllUsers(
          allMembers.filter((val) =>
            val.toLowerCase().includes(value.toLowerCase())
          )
        );
      } else {
        //Filter from Members
        setMembers(
          groupMembers.filter((val) =>
            val.toLowerCase().includes(value.toLowerCase())
          )
        );
      }
    }, 500);
  };

  const getListItemCss = (record, title) =>{
    if(title === ALL_USERS){
      return {
        color: removedUsers.indexOf(record) >= 0 ? "#fff" : "#000",
        backgroundColor: removedUsers.indexOf(record) >= 0 ? "red" : "#fff"
      };
    }
    else{
      return {
        color: addedUsers.indexOf(record) >= 0 ? "#fff" : "#000",
        backgroundColor: addedUsers.indexOf(record) >= 0 ? "green" : "#fff"
      };
    }
  }

  const getUserRole = (roleString) =>{
    if(roleString.indexOf("reader")>=0){
      return "reader";
    }
    else if(roleString.indexOf("author")>=0){
      return "author";
    }
    else if(roleString.indexOf("admin")>=0){
      return "admin";
    }
    else{
      return roleString;
    }
  }

  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            style={{ color: "#035fa4" }}
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ "aria-label": "all items selected" }}
          />
        }
        titleTypographyProps={{ variant: "h6" }}
        title={title}
        subheaderTypographyProps={{ variant: "h5" }}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <TextField
        className="qs_user_search"
        placeholder="Search User"
        style={{
          width: "100%",
          fontSize: "14px"
        }}
        InputProps={{
          startAdornment: (
              <InputAdornment>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
          ),
        }}
        onChange={(e) => {
          filterUser(e.target.value, title);
        }}
      />
      <Divider />
      <table style={{ width: "100%", marginTop: "6px" }}>
        <tbody>
          <tr>
            <td style={{ width: "75%", paddingLeft: "73px" }}>
              <label style={{ fontSize: "15px" }}>Email</label>
            </td>
            <td style={{ width: "25%" }}>
              <label style={{ fontSize: "15px" }}>Role</label>
            </td>
          </tr>
        </tbody>
      </table>
      <Divider />
      {title === ALL_USERS
        ? groupMembersloading && (
            <div className={classes.root} style={{ marginLeft: "280px" }}>
              <CircularProgress />
            </div>
          )
        : allMembersLoading && (
            <div className={classes.root} style={{ marginLeft: "280px" }}>
              <CircularProgress />
            </div>
          )}
      <List className={classes.list} dense component="div" role="list">
        {items &&
          items.map((value) => {
            const labelId = `transfer-list-all-item-${value}-label`;

            return (
              <ListItem
                key={value}
                role="listitem"
                button
                onClick={handleToggle(value)}
                style={getListItemCss(value, title)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    style={{ color: "#035fa4" }}
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={value.indexOf("/") === -1 ? value : value.split("/")[1]}
                  primaryTypographyProps={{ variant: "h6" }}
                  style={{width: "300px"}}
                />
                <ListItemText
                  primaryTypographyProps={{ variant: "h6" }}
                  id={labelId}
                  primary={value.indexOf("/") === -1 ? value : getUserRole(value.split("/")[0])}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                />
              </ListItem>
            );
          })}
        <ListItem />
      </List>
    </Card>
  );

  useEffect(() => {
    //Get records by group name and account id
    getUserByGroup(
      props.selectedRecord.Group_name,
      props.selectedRecord.Aws_account
    );
    setAllMembersLoading(true);
    setGroupMembersLoading(true);
    searchUserByText();
  }, []);

  const getUserByGroup = async (group_name, account) => {
    let res = await listUserByGroup(group_name, account);
    setAllMembersLoading(false);
    let arr = [];
    res.data.map((e) => {
      arr.push(e.User_name);
    });
    let sortedArray = arr.sort((a,b) => {
      let user1 = a.indexOf("/") === -1 ? a : a.split("/")[1].toLowerCase();
      let user2 = b.indexOf("/") === -1 ? b : b.split("/")[1].toLowerCase();
      return (user1 > user2) ? 1 : ((user2 > user1) ? -1 : 0);
    })
    setMembers(sortedArray);
    setGroupMembers(sortedArray);
  };

  const deleteUser = async (member_name) => {
    await deleteUserAPI(
      props.selectedRecord.Group_name,
      props.selectedRecord.Aws_account,
      member_name
    );
    // getUserByGroup(
    //   props.selectedRecord.Group_name,
    //   props.selectedRecord.Aws_account
    // );
  };

  const addUser = async (member_names) => {
    let res = await addUserAPI(
      props.selectedRecord.Group_name,
      props.selectedRecord.Aws_account,
      member_names
    );
    // getUserByGroup(
    //   props.selectedRecord.Group_name,
    //   props.selectedRecord.Aws_account
    // );
  };

  const searchUserByText = async () => {
    setTimeout(async () => {
      let result = [];
      let nextToken = null;
      do{
        let result1 = await searchUser(
            props.selectedRecord.Aws_account,
            props.selectedRecord.Group_name,
            nextToken
        );
        nextToken = result1.data.NextToken;
        result = result.concat(result1.data.UserList)
      }while (nextToken);

      setGroupMembersLoading(false);
      let sortedData = result.sort((a,b) => {
        let user1 = a.indexOf("/") === -1 ? a : a.split("/")[1].toLowerCase();
        let user2 = b.indexOf("/") === -1 ? b : b.split("/")[1].toLowerCase();
        return (user1 > user2) ? 1 : ((user2 > user1) ? -1 : 0);
      })
      setAllMembers(sortedData);
      setAllUsers(sortedData);
    }, 2000);
  };

  return (
    <>
      <div
        style={{
          verticalAlign: "middle",
          marginLeft: "20px",
          width: "100%",
        }}
      >
        <i
          data-feather="chevron-left"
          aria-hidden="true"
          style={{ verticalAlign: "middle" }}
        ></i>
        <button
          className="pds-link-emphasis"
          style={{ verticalAlign: "middle", marginLeft: "0px" }}
          onClick={() => {
            props.setIsQSSetting(true);
          }}
        >
          back
        </button>
        <div style={{ textAlign: "center", marginTop: "-32px" }}>
          <label
            style={{
              verticalAlign: "middle",
            }}
          >
            Group Name: {props.selectedRecord.Group_name}
          </label>
        </div>
      </div>

      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        className={classes.root}
        style={{ marginTop: "15px" }}
      >
        <Grid item>{customList(ALL_USERS, allUsers)}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="large"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={allUsersChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="large"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={allMembersChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          {customList("Users in " + props.selectedRecord.Group_name, members)}
        </Grid>
      </Grid>

      <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          className={classes.root}
          style={{ marginTop: "15px" }}>
        <SolidButton
            disabled={addedUsers.length || removedUsers.length }
            size="sm"
            value="Save"
            onClick={()=>saveChanges()}
        />

        <ButtonDefault
            disabled={addedUsers.length || removedUsers.length }
            size="sm"
            value="Discard changes"
            onClick={()=>discardChanges()}
        />
      </Grid>

      {
        alertData.show ?
            <div
                className="pds-form"
                style={{
                  paddingLeft: "40px",
                  paddingRight: "200px",
                }}
            >
              <div
                  className={"pds-alert pds-alert-"+alertData.type}
                  style={{
                    position: "fixed",
                    left: "0",
                    bottom: "0",
                    right: "0",
                    marginBottom: "80px",
                    marginLeft: "20px",
                    marginRight: "20px",
                  }}
              >
                <div className="pds-card-section">
                  <div className="pds-alert-content" role="alert">
                    <span className="sr-only">Success. </span>
                    {alertData.message}
                  </div>
                </div>
              </div>
            </div>
            : null
      }
    </>
  );
};
