import React from "react";
import LoadingOverlay from 'react-loading-overlay';
import "./Spinner.css";

const Spinner = props => {
    return (
        <LoadingOverlay
            active={props.loading}
            spinner
            text={props.loadingMessage ? props.loadingMessage : 'Loading your content...'}
        />
    );
};

export default Spinner;