import React from "react";
import "./HomePage.css";
import GlobalSearch from "../GlobalSearch/GloablSearch";

const HomePage = () => {
  return (
      <div className="text-center">
        <h1 className="text-center">Welcome to the GA Launchpad</h1>
        {/*<p className="landing-page-note">Note: If you want to access Quicksight dashboards,*/}
        {/*    On-Premises reports, Applications, Self-service or Feedback,*/}
        {/*    please <a className="v2-link" href={window.location.origin + "/v2/"}>Switch to Launchpad v2</a>.</p>*/}
        <GlobalSearch />
      </div>
  );
};

// function mapStateToProps(state) {
//   return {

//   };
// }

// const mapDispatchToProps = {
//   setWindowZIndex,
// };

// export default React.memo(
//   connect(mapStateToProps, mapDispatchToProps)(HomePage)
// );

export default HomePage;
