import { LOGIN_FLAG,QUICK_LINKS } from '../actions/login.action';

const initialState = {
  flag:false,
  mode:'view',
  //isNewRecordType:false,
  search:null

};

export function LoginReducer (state = initialState, action)  {
  switch (action.type) {
    case LOGIN_FLAG:
      return {
        ...state,
        flag:action.flag
      };
    case QUICK_LINKS:
      return{
        ...state,
        mode:action.mode
        //isNewRecordType:action.isNewRecordType
      };
    default:
      return state;
  }
};


