import React, { FC, useEffect, useState } from "react";
import {
  createQSGroup,
  deleteQSGroup,
  getAllQSGroups,
} from "../../api/apiUtils";
import { UserSetting } from "./userSetting.tsx";
import {Unauthorized} from "../errorPages/Unauthorized.tsx";

export const QuickSightSetting: FC = () => {
  const [showNewGroup, setShowNewGroup] = useState(false);
  const [groupData, setGroupData] = useState([]);
  const [accountIds, setAccountIds] = useState();
  const [accountNames, setAccountNames] = useState();
  const [groupName, setGroupName] = useState();
  const [groupDescription, setGroupDescription] = useState();
  const [selectedAccount, setSelectedAccount] = useState();
  const [selectedRecord, setSelectedRecord] = useState();
  const [isQSSetting, setIsQSSetting] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [successMsg, setSuccessMsg] = useState();
  const [failureMsg, setFailureMsg] = useState();
  let isUpdateAllowed = localStorage.getItem("hasUpdateAccess");
  isUpdateAllowed = JSON.parse(isUpdateAllowed);

  useEffect(() => {
    document.title = "QS manage users";
    const script = document.createElement("script");
    script.src =
      "https://www.principalcdn.com/css/principal-design-system/pds/latest/pds.min.js";
    script.async = true;
    document.body.appendChild(script);
    setTimeout(() => {
      const script = document.createElement("script");
      script.src =
        "https://www.principalcdn.com/css/principal-design-system/dataTable/v13/dataTable.min.js";
      script.async = true;
      document.body.appendChild(script);
    }, 5000);
    getAllGroups();
  }, []);

  const getAllGroups = async () => {
    let res = await getAllQSGroups();
    let arrAccountIds = [];
    let arrAccountNames = [];
    let ele = document.getElementsByClassName("pds-dataTable");
    setGroupData(res.data.group_list);
    res.data.account_list.map((record) => {
      if (arrAccountIds.indexOf(record.Aws_account) === -1) {
        arrAccountIds.push(record.Aws_account);
        arrAccountNames.push(record.Aws_account_name);
      }
    });
    setAccountIds(arrAccountIds);
    setAccountNames(arrAccountNames);
  };

  const deleteGroup = async (record) => {
    let res = await deleteQSGroup(record.Group_name, record.Aws_account);
    if (res.status === 200) {
      setSuccessMsg("Group Deleted Succesfully");
      setIsSuccess(true);
      setTimeout(
        function () {
          setIsSuccess(false);
        }.bind(this),
        5000
      );
    } else {
      setFailureMsg(res.data);
      setIsFailure(true);
      setTimeout(
        function () {
          setIsFailure(false);
        }.bind(this),
        5000
      );
    }
    getAllGroups();
  };

  const createGroup = async () => {
    let res = await createQSGroup(groupName, groupDescription, selectedAccount);
    if (res.status === 200) {
      setIsSuccess(true);
      setSuccessMsg(res.data);
    } else {
      setIsFailure(true);
      setFailureMsg(res.data);
    }
    setTimeout(() => {
      setIsSuccess(false);
      setIsFailure(false);
    }, 4000);
    getAllGroups();
    setShowNewGroup(false);
  };

  return (
      <>
      {
        isUpdateAllowed ?
            <>
      {isQSSetting ? (
        <>
          <div style={{ verticalAlign: "middle" }}>
            <button
              className="pds-button pds-button-tertiary-border"
              style={{
                marginLeft: "30px",
                textAlign: "left",
                verticalAlign: "left",
              }}
              onClick={() => {
                setShowNewGroup(true);
              }}
            >
              New Group
            </button>

            {showNewGroup && (
              <div style={{ marginTop: "-32px", marginLeft: "200px" }}>
                <input
                  id="helperInput"
                  aria-label=""
                  type="text"
                  aria-describedby="helper-text-id"
                  placeholder="Enter Group Name"
                  style={{ height: "50px", paddingLeft: "7px" }}
                  onChange={(e) => {
                    setGroupName(e.target.value);
                  }}
                />
                <input
                  id="helperInput"
                  aria-label=""
                  type="text"
                  aria-describedby="helper-text-id"
                  placeholder="Enter Group Description"
                  style={{
                    height: "50px",
                    paddingLeft: "7px",
                    marginLeft: "16px",
                  }}
                  onChange={(e) => {
                    setGroupDescription(e.target.value);
                  }}
                />
                <select
                  id="selectPlaceholder"
                  aria-label="Select placeholder"
                  name="select-input"
                  style={{
                    height: "50px",
                    marginLeft: "16px",
                    paddingLeft: "7px",
                    paddingRight: "7px",
                  }}
                  onChange={(e) => {
                    setSelectedAccount(e.target.value);
                  }}
                >
                  <option value="">Select Account</option>
                  {accountNames.map((record, index) => {
                    return <option value={accountIds[index]}>{record}</option>;
                  })}
                </select>
                <button
                  className="pds-button pds-button-tertiary-border"
                  style={{
                    marginLeft: "30px",
                    textAlign: "left",
                    verticalAlign: "left",
                  }}
                  onClick={() => {
                    createGroup();
                  }}
                >
                  Add Group
                </button>
              </div>
            )}
          </div>
          <div
            className="pds-dataTable"
            style={{
              overflowY: "hidden",
              paddingRight: "150px",
              paddingLeft: "150px",
            }}
          >
            <div className="pds-dataTable-header">
              <p aria-hidden="true" className="pds-dataTable-title"></p>
              <div className="pds-searchInput pds-dataTable-smart-search">
                <input
                  aria-label="Filter table data search bar"
                  className="search inputSearch pds-input-small"
                  type="search"
                  placeholder="What are you looking for?"
                />
                <button
                  type="submit"
                  aria-label="Search"
                  aria-disabled="true"
                ></button>
              </div>
            </div>
            <div className="pds-dataTable-table-container pds-table-scrolling ">
              <table
                className="pds-table-container pds-table pds-table-bordered"
                id="pds-datatable-example"
                aria-live="polite"
              >
                <caption className="sr-only">Title</caption>
                <thead>
                  <tr>
                    <th
                      className="sort pds-dataTable-table-header"
                      scope="col"
                      style={{
                        minWidth: "0px",
                        width: "400px",
                        textAlign: "left",
                      }}
                    >
                      Group Name
                    </th>
                    <th
                      className="sort pds-dataTable-table-header"
                      scope="col"
                      style={{
                        minWidth: "0px",
                        width: "400px",
                        textAlign: "left",
                      }}
                    >
                      Description
                    </th>
                    <th
                      className="sort pds-dataTable-table-header"
                      scope="col"
                      style={{
                        minWidth: "0px",
                        width: "400px",
                        textAlign: "left",
                      }}
                    >
                      Account
                    </th>
                    {/* <th
                      className="sort pds-dataTable-table-header"
                      scope="col"
                      style={{
                        minWidth: "0px",
                        textAlign: "left",
                      }}
                    >
                      Action
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  <>
                    {groupData &&
                      groupData.map((record) => {
                        return (
                          <tr style={{ textAlign: "left" }}>
                            <td
                              className="pds-dataTable-table-item"
                              style={{
                                cursor: "pointer",
                                color: "#0060af",
                              }}
                              onClick={() => {
                                setIsQSSetting(false);
                                setSelectedRecord(record);
                              }}
                            >
                              {record.Group_name}
                            </td>
                            <td className="pds-dataTable-table-item">
                              {record.Description}
                            </td>
                            <td className="pds-dataTable-table-item">
                              {record.Aws_account_name}
                            </td>
                            {/* <td>
                              <button
                                onClick={() => {
                                  deleteGroup(record);
                                }}
                                style={{
                                  backgroundColor: "transparent",
                                  border: "0",
                                }}
                              >
                                <a>Remove</a>
                              </button>
                            </td> */}
                          </tr>
                        );
                      })}
                  </>
                </tbody>
              </table>
            </div>
          </div>
          {isSuccess && (
            <div
              className="pds-form"
              style={{
                paddingLeft: "40px",
                paddingRight: "200px",
              }}
            >
              <div
                className="pds-alert pds-alert-success"
                style={{
                  position: "fixed",
                  left: "0",
                  bottom: "0",
                  right: "0",
                  marginBottom: "80px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              >
                <div className="pds-card-section">
                  <div className="pds-alert-content" role="alert">
                    <span className="sr-only">Success. </span>
                    {successMsg}
                  </div>
                </div>
              </div>
            </div>
          )}
          {isFailure && (
            <div
              className="pds-form"
              style={{ paddingLeft: "40px", paddingRight: "200px" }}
            >
              <div
                className="pds-alert pds-alert-error"
                style={{
                  position: "fixed",
                  left: "0",
                  bottom: "0",
                  right: "0",
                  marginBottom: "80px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              >
                <div className="pds-card-section">
                  <div className="pds-alert-content" role="alert">
                    <span className="sr-only">Success. </span>
                    {failureMsg}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <UserSetting
          selectedRecord={selectedRecord}
          setIsQSSetting={setIsQSSetting}
        />
      )}
    </>
            :
            <Unauthorized />
      }
  </>
  );
};
