import React, {useEffect} from "react";
import { withOAuth } from "aws-amplify-react";
import Button from "../guiComponents/Buttons/Button";
// import Button from '../../components/UI/Buttons/Button'
import { Auth } from "aws-amplify";

const Redirect = (props) => {
  const handleLogin = () => {
    //window.location.assign( 'https://ddm-staging-api-token.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=ddm-id-provider&redirect_uri=http://localhost:3000&response_type=TOKEN&client_id=47ha324qj1agn0uds9o03m1qo5 &state=thisisstate09283781918&scope=ddm-user-pool-resource-identifiers/access_db_data aws.cognito.signin.user.admin email openid')
    // window.location.assign(process.env.REACT_APP_LOGIN_URL)

    localStorage.setItem("startpage", "startpage");

    Auth.federatedSignIn({ provider: process.env.REACT_APP_IDENTITY_PROVIDER });

    ///localStorage.removeItem("startpage")
  };

  useEffect(()=>{
    // handleLogin();
    if(sessionStorage.getItem("autologin") !== "1"
        && (props.locationSearch === "" || props.locationSearch === undefined ||
            (props.locationSearch && props.locationSearch.indexOf("code") === -1))
    ) {
      sessionStorage.setItem("autologin", "1");
      setTimeout(()=>{
        handleLogin();
      }, 500)
    }

  }, [])

  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <Button
          onClick={() => handleLogin()}
          disabled={true}
          value={"Organization single sign on"}
          size={"sm"}
        > </Button>
      </div>
    </>
  );
};
export default withOAuth(Redirect);
